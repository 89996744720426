import styled from "styled-components";
import learnMoreImage from "../../Assets/Images/learnMoreImage.png";

export const LearnMoreStyles = styled.div`

.hero {
    background-image: url(${learnMoreImage});
    background-size: cover;
    background-position: center;
    color: white;
    padding: 40px 20px;
    text-align: left;
    height: 40vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.text-container {
    width: 40%;
}

.sign-up-button {
    background: linear-gradient(to right, rgb(0, 94, 132), rgb(0, 153, 153));
    border: none;
    border-radius: 15px;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer; 
}

.info-section {
    padding: 50px 20px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: -40px;
    margin: 5px 0;
}

.info-section h2 {
    font-size: 38px;
    margin-bottom: 10px;
}

.info-section p {
    font-size: 20px;
    color: #666;
}

.info-section img {
    width: 45px;
    height: 45px;
    object-fit: contain;
    display: block;
}

#black {
    background-color: black;
    color: white;
}

.cta-button {
    background: linear-gradient(to right, rgb(0, 94, 132), rgb(0, 153, 153));
    border: none;
    border-radius: 15px;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.features, .benefits{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.feature, .benefit {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background: #fff;
    border-radius: 15px;
    padding: 25px;
    text-align: center;
    max-width: 280px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.benefit {
    justify-content: center;
}

.feature h3, .benefit h3 {
    font-size: 28px;
    margin-bottom: 5px;
}

.feature p, .benefit p {
    font-size: 16px;
    margin: 0;
}

.feature .cta-button {
    width: 65%;
}

#black p {
    color: #999;
}

.benefit h3 {
    color: black;
}

.benefit p {
    color: #666 !important;
}

.benefits {
    margin-bottom: 20px;
}

.feature:hover, .benefit:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
}

.final-cta {
    background-color: #fff;
    padding: 50px 20px;
    text-align: center;
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        text-align: center;
        height: auto; 
        padding: 20px;
    }

    .text-container {
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        background: rgba(75, 75, 75, 0.4); /* Dark semi-transparent background */
        backdrop-filter: blur(10px); /* iOS-style blur effect */
        -webkit-backdrop-filter: blur(10px); /* Safari support */
        border-radius: 10px;
    }

    .text-container p {
        margin-bottom: 0;
    }

    .sign-up-button {
        padding: 12px 20px;
        font-size: 14px;
        width: 60%;
        max-width: 300px;
        align-self: center;
    }

    .cta-button {
        padding: 12px 20px;
        font-size: 14px;
        width: 60%;
        max-width: 300px;
    }

    /* Info Section */
    .info-section {
        padding: 30px 15px;  /* Reduce padding */
    }

    .info-section h2 {
        font-size: 28px;  /* Decrease size */
    }

    .info-section p {
        font-size: 16px;
    }

    /* Features & Benefits - Stack Items */
    .features, .benefits {
        flex-direction: column; /* Stack instead of row */
        align-items: center;  /* Center-align */
    }

    .feature, .benefit {
        width: 100%;  /* Allow more width */
        padding: 20px;  /* Reduce padding */
        gap: 15px;
    }

    /* Reduce Shadow & Animation Intensity */
    .feature:hover, .benefit:hover {
        transform: translateY(-3px);
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
    }

    /* Image Adjustments */
    .info-section img {
        width: 40px;  /* Slightly smaller */
        height: 40px;
    }

    /* Final CTA Section */
    .final-cta {
        padding: 30px 15px;
    }
}
`;