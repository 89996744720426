import styled from "styled-components";

export const GroupDetailsStyles = styled.div`
    .section-title {
        margin: .5em 0;
        font-size: 1.2em;
        padding-top: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h3 {
        font-size: 1.2em;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        margin-top: 10px;
        margin-bottom: 0px;
        padding-left: 10px;
        margin-right: 10px;
        background: none;
    }

    .members-section {
        padding-bottom: 10px;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .header .requests-bubble {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0.5em .5em;
        padding: 5px 10px;
        background: rgba(200, 200, 200, 0.7);
        border-radius: 15px / 50%;
        font-size: 14px;
        font-weight: bold;
        color: black;
        cursor: pointer;
        position: relative;
        border: none;
        outline: none;
        transition: background 0.3s ease-in-out;
    }

    .requests-bubble:hover {
        background: rgba(180, 180, 180, 0.9);
    }

    /* Exclamation Icon */
    .alert-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        background-color: orange;
        color: white;
        font-size: 12px;
        font-weight: bold;
        border-radius: 50%;
        margin-left: 8px;
        position: relative;
    }

    .members-list {
        border: 1px solid gray;
        border-radius: 10px;
        padding: 0 .5em;
        margin: 0;
        background-color: #ffffff;
        list-style-type: none;
    }

    li {
        border-bottom: 1px solid gray;
        padding: .5em 0;
    }

    li:last-child {
        border-bottom: none;
    }

    .friend-object {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .friend-info {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .friend-profile-photo {
        width: 2.5em;
        height: 2.5em;
        border: 2px solid grey;
        border-radius: 25px;
    }

    h4 {
        margin: 0;
        font-size: 1.25em;
    }

    p {
        margin: 0;
        font-size: .8em;
    }

    .remove-button {
        background-color: red;
        color: white;
        border: none;
        border-radius: 10px;
    }

    .buttons-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .buttons-container {
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .delete-group-button {
        border: none;
        border-radius: 5px;
        text-align: center;
        justify-content: center;
        font-size: 13pt;
        font-family "Myriad Pro"
        align-self: flex-end;
        background-color: red;
        color: white;
    }

    .message-section {
        margin: 1em 0;
        text-align: center;
    }

    .cancel-button {
        background-color: red;
        color: white;
        border: none;
        background-color: #005fff;
        border-radius: 10px;
    }

    .add-friend-button {
        border: none;
        border-radius: 5px;
        text-align: center;
        justify-content: center;
        font-size: 12pt;
        font-family "Myriad Pro";
        background-color: #005fff;
        color: white;
    }

    .add-friend-button:disabled, .delete-group-button:disabled {
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed;
    }

    .merge-button {
        border: none;
        border-radius: 5px;
        text-align: center;
        font-size: 10pt;
        background-color: #009999;
        color: white;
    }

    .merge-icon {
        width: 15px;
        height: 15px;
        margin-left: 5px;
        margin-bottom: 3px;
        filter: invert(1);
    }

    .result-type-button {
        border: none;
        border-radius: 5px;
        text-align: center;
        font-size: 20pt;
        background-color: #009999;
        color: white;
    }
`;