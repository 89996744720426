import styled from "styled-components";

export const SelectResultsStyles = styled.div`
.results-section {
    margin-top: 20px;
}

.results-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    overflow-y: auto;
    width: auto !important;
}

.pinned-results {
    max-height: 200px;
}

.past-results {
    max-height: 300px;
}

h3 {
    padding-left: 10px;
    margin-right: 10px;
    font-size: 150%;
    background: none;
}

.result-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    padding: 10px 0;
    border-bottom: 1px solid #eee; 
}

.result-item:last-child {
    border-bottom: none;
}

.result-info {
    flex: 1; 
    margin-right: 10px; 
}

.result-info h4 {
    margin: 0; 
}

.select-button {
    align-self: center;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.select-button:hover {
    background-color: #0056b3;
}

.message {
    margin: 2em 0;
}

.close-button {
    position: absolute;
    top: 10px;  
    right: 10px; 
    background: transparent; 
    border: none;  
    font-size: 20px; 
    cursor: pointer;   
    color: #333;       
}

.close-button:hover {
    color: red;        /* Change color on hover for visibility */
}
`;