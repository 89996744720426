import { useState, useEffect } from "react";
import { ProfileStyles, ResultBox } from "../profile-helpers/profile-styles";
import fetchResults from "../results/fetchResults";
import { DestinationRankings } from "../survey-helpers/suggestion-algorithm/suggestion-data";
import DisplayResults from "../game-helpers/DisplayResults";
import unfriendIcon from "../../Assets/Images/unfriend.png";
import unfriend from "./unfriend";
import mergeIcon from "../../Assets/Images/merge.png";
import { MergeHubStyles } from "../merge-helpers/MergeHubStyles";
import SelectResultsForMerge from "../merge-helpers/SelectResultsForMerge";
import Modal from "../../Modals/survey/Modal";
import { generateMergeData } from "../merge-helpers/performMerge";
import saveResultIcon from "../../Assets/Images/saveResult.png";
import performSurveyMerge from "../merge-helpers/performSurveyMerge";
import { postMergeResult } from "../merge-helpers/performMerge";
import postSurveyMergeResult from "../merge-helpers/postSurveyMergeResult";

const FriendProfileView = ({friend, userId, firstName, lastName, setShowBackButton, setCurrentFriends, setIsViewingFriend}) => {
    const [gameResults, setGameResults] = useState([]);
    const [pinnedGameResults, setPinnedGameResults] = useState([]);
    const [surveyResults, setSurveyResults] = useState([]);
    const [pinnedSurveyResults, setPinnedSurveyResults] = useState([]);
    const [isViewingResults, setIsViewingResults] = useState(false);
    const [selectedResult, setSelectedResult] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [isViewingMergeResults, setIsViewingMergeResults] = useState(false);
    const [mergeResult, setMergeResult] = useState(null);
    const [resultsSaved, setResultsSaved] = useState(false);
    const [surveyPostData, setSurveyPostData] = useState([]);

    useEffect(() => {
        const fetchFriendResults = async () => {
            try {
                const { pinnedData, pastData, surveyData, pinnedSurveyData } = await fetchResults(friend.userId);

                setPinnedGameResults(pinnedData);
                setGameResults(pastData);
                setSurveyResults(surveyData);
                setPinnedSurveyResults(pinnedSurveyData);
            } catch (err) {
            }
        };

        if (friend) {
            fetchFriendResults();
        };
    }, [friend])

    useEffect(() => {
        if (isViewingResults || isViewingMergeResults) {
            setShowBackButton(false);
        } else {
            setShowBackButton(true);
        }
    }, [isViewingResults, setShowBackButton, isViewingMergeResults]);

    const handleViewGameResults = (result, subcollection) => {
        // Map the city names to their corresponding data from DestinationRankings
        const enrichedRankings = result.top8Destinations.map(city => ({
          city,
          ...DestinationRankings[city]
        }));
    
        setSelectedResult({
          id: result.id,
          rankings: enrichedRankings,
          subcollection: subcollection,
          ...result
        });
        setIsViewingResults(true);
    };

    const handleViewSurveyResults = (result, subcollection) => {
        const top8Destinations = result.results.slice(0,8);
        const cityNames = top8Destinations.map(destination => destination.city);

        const enrichedRankings = cityNames.map(city => ({
          city,
          ...DestinationRankings[city]
        }));
    
        setSelectedResult({
          id: result.id,
          rankings: enrichedRankings,
          subcollection: subcollection,
          ...result
        });
        setIsViewingResults(true);
    };

    const handleUnfriend = async (friendUserId) => {
        setCurrentFriends(prevFriends =>
            prevFriends.filter(friend => friend.userId !== friendUserId)
        );

        await unfriend(userId, friendUserId);

        //Navigate them back
        setIsViewingFriend(false);
    };

    const handleMergeClick = async (selectedResult) => {
        setIsModalOpen(true);
    };

    const handleViewMergeResults = (result) => {
        const enrichedRankings = result.top8Destinations.map(city => ({
            city,
            ...DestinationRankings[city]
        }));

        setMergeResult({
            rankings: enrichedRankings,
            ...result
        });

        setIsViewingResults(false);
        setIsViewingMergeResults(true);
        setSuccess(false);
    };

    const handleViewSurveyMergeResults = (result) => {
        const top8Destinations = result.slice(0,8);
        const cityNames = top8Destinations.map(destination => destination.city);

        const enrichedRankings = cityNames.map(city => ({
          city,
          ...DestinationRankings[city]
        }));
    
        setMergeResult({
          rankings: enrichedRankings,
        });

        setIsViewingResults(false);
        setIsViewingMergeResults(true);
        setSuccess(false);
    };

    const handleResultSelect = (userResult) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        if (selectedResult.subcollection === "Game" || selectedResult.subcollection === "Pinned") {
            try {
                const mergeData = generateMergeData(userResult, selectedResult);
                handleViewMergeResults(mergeData);
                setSuccess(true);
            } catch (error) {
                setError(`Error merging results: ${error.message}` || "Error merging results");
            } finally {
                setLoading(false);
                setSuccess(false);
                setError(null);
                setIsModalOpen(false);
            }
        } else {
            try {
                const mergeData = performSurveyMerge(userResult, selectedResult);
                handleViewSurveyMergeResults(mergeData);
                setSuccess(true);
            } catch (error) {
                setError(`Error merging results: ${error.message}` || "Error merging results");
            } finally {
                setLoading(false);
                setSuccess(false);
                setError(null);
                setIsModalOpen(false);
            }
        }
    };

    const handleSaveResults = async (result) => {
        setIsModalOpen(true);
        setSuccess(false);

        //Condition to make sure users can't spam save results button
        //Displays all false case text in modal <p> condition
        if (resultsSaved) {
            return;
        } else {
            setLoading(true);
            setError(null);
        }

        //Determine what kind of merge was performed
        if (selectedResult.subcollection == "Game" || selectedResult.subcollection == "Pinned") {
            try {
                await postMergeResult(userId, firstName, lastName, friend, result);
                setResultsSaved(true);
                setSuccess(true);
            } catch (error) {
                setError(`Error posting results: ${error.message}` || "Error posting results");
                setLoading(false);
            } finally {
                setLoading(false);
            }
        } else {
            try {
                const cityNames = result.rankings.map(destination => destination.city);
                await postSurveyMergeResult(userId, firstName, lastName, friend, cityNames);
                setResultsSaved(true);
                setSuccess(true);
            } catch (error) {
                setError(`Error posting results: ${error.message}` || "Error posting results");
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };
    };
    
    const onClose = () => {
        setIsModalOpen(false);
    };

    const handleBackButtonClick = () => {
        setIsViewingMergeResults(false);
        setResultsSaved(false);
    };

    if (isViewingResults) {
        return (
            <ProfileStyles>
                <MergeHubStyles>
                    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                        <SelectResultsForMerge 
                            userId={userId}
                            onClose={onClose}
                            onSelect={handleResultSelect}
                            subcollection={selectedResult.subcollection}
                            loading={loading}
                            error={error}
                            success={success}
                        />
                    </Modal>
                </MergeHubStyles>
                
                <div>
                    <div className='header'>
                        <button onClick={() => setIsViewingResults(false)} className='back-button'>
                            ← Back
                        </button>
                        <button className='merge-button' onClick={() => handleMergeClick(selectedResult)}
                            style={{ marginRight: '16px' }}>
                            Merge Results
                            <img src={mergeIcon} className='merge-icon' />
                        </button>
                    </div>
                    <div className='profile-container'>
                        <DisplayResults
                            rankings={selectedResult.rankings}
                            DestinationRankings={DestinationRankings}
                        />
                     </div>
                </div>
            </ProfileStyles>
        )
    }

    if (isViewingMergeResults) {
        return (
            <ProfileStyles>
                {/* Save results click modal */}
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <div>
                        <h3>Save results</h3>
                        {loading ? (
                            <p>Loading...</p>
                        ) : error ? (
                            <p>Error saving results: {error}</p>
                        ) : success ? (
                            <p>Results saved to accounts.</p>
                        ) : (
                            <p>Results already saved. Try with different results if you'd like!</p>
                        )}
                    </div>
                </Modal>
                <div>
                    <div className='header'>
                        <button onClick={() => handleBackButtonClick()} className='back-button'>
                            ← Back
                        </button>
                        <button className='merge-button' onClick={() => handleSaveResults(mergeResult)}
                            style={{ marginRight: '16px' }}>
                            Save Results
                            <img src={saveResultIcon} className="save-result-icon"/>
                        </button>
                    </div>
                    <div className='profile-container'>
                        <DisplayResults
                            rankings={mergeResult.rankings}
                            DestinationRankings={DestinationRankings}
                        />
                     </div>
                </div>
            </ProfileStyles>
        )
    }

    return (
        <ProfileStyles>
            <div className="profile-container">
                <div className="profile-content">
                    <div className="profile-info">
                        <div className="profile-left">
                            <img
                                src={friend.profileImage}
                                className="profile-image"
                                alt="Profile"
                            />
                            <div className='profile-text'>
                                <h2>{friend.firstName} {friend.lastName}</h2>
                                <p className='username'>@{friend.userName}</p>
                            </div>
                        </div>
                        <div className="buttons-container">
                                <button className="unfriend-button" onClick={() => handleUnfriend(friend.userId)}>
                                    Unfriend
                                    <img src={unfriendIcon} className="unfriend-icon" alt="unfriend"/>
                                </button>
                            </div>
                    </div>
                </div>

                <div className='results-content'>
                    <div className="results">
                        <div className="pinned-results">
                            <h3>Game Results</h3>
                            {pinnedGameResults.map(result => (
                                <ResultBox key={result.id}>
                                    <div className='heading'>
                                        <h4>{result.id}</h4>
                                    </div>
                                    <div className='summary'>
                                        <p>Top Destination: {result.top8Destinations[0]}</p>
                                        <button onClick={() => handleViewGameResults(result, "Pinned")} className='see-more-button'>
                                            See More →
                                        </button>
                                    </div>
                                </ResultBox>
                            ))}
                            {gameResults.length > 0 ? (
                                gameResults.map(result => (
                                    <ResultBox key={result.id}>
                                        <div className='heading'>
                                            <h4>{result.id}</h4>
                                        </div>
                                        <div className='summary'>
                                            <p>Top Destination: {result.top8Destinations[0]}</p>
                                            <button onClick={() => handleViewGameResults(result, "Game")} className='see-more-button'>
                                            See More →
                                            </button>
                                        </div>
                                    </ResultBox>
                                ))
                            ) : (
                                <p>No game results.</p>
                            )}
                        </div>
                        <div className="past-results">
                            <h3>Survey Results</h3>
                            {pinnedSurveyResults.map(result => (
                                <ResultBox key={result.id}>
                                    <div className="heading">
                                        <h4>{result.name}</h4>
                                    </div>
                                    <div className="summary">
                                        <p>Top Destination: {result.results[0].city}</p>
                                    </div>
                                    <button onClick={() => handleViewSurveyResults(result, "SurveyPinned")} className='see-more-button'>
                                        See More →
                                    </button>
                                </ResultBox>
                            ))}
                            {surveyResults.length > 0 ? (
                                surveyResults.map(result => (
                                    <ResultBox key={result.id}>
                                        <div className="heading">
                                            <h4>{result.name}</h4>
                                        </div>
                                        <div className="summary">
                                            <p>Top Destination: {result.results[0].city}</p>
                                            <button onClick={() => handleViewSurveyResults(result, "Survey")} className='see-more-button'>
                                                See More →
                                            </button>
                                        </div>
                                    </ResultBox>
                                ))
                            ) : (
                                <p>No survey results.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ProfileStyles>
    )
};

export default FriendProfileView;