import { db } from "../../firebase";
import { doc, getDoc, addDoc, updateDoc, deleteDoc, collection, arrayUnion } from "firebase/firestore";
import performSurveyMerge from "./performSurveyMerge";

const performGroupSurveyMerge = async (userId, userResult, request) => {
    //Check if user is first to respond
    const isFirstResponder = !request.mergeDocId;

    //If first to respond get friend's data. If not get current merge data
    let currentResult;
    if (isFirstResponder) {
        try {
            const friendResultDoc = doc(db, `Results/${request.sentBy}/${request.subcollection}/${request.resultId}`);
            const friendResultSnapshot = await getDoc(friendResultDoc);
            currentResult = friendResultSnapshot.data();
        } catch (error) {
            console.error("Error finding friend's result document:", error.message);
        }
    } else {
        try {
            const mergeDoc = doc(db, `Merges/${request.mergeDocId}`);
            const mergeDocSnapshot = await getDoc(mergeDoc);
            currentResult = mergeDocSnapshot.data();
        } catch (error) {
            console.error("Error finding merge doc:", error.message);
        }
    }

    //Perform merge
    const newMergeData = performSurveyMerge(userResult, currentResult);

    //Extract city names from top 8 destinations
    const top8Destinations = newMergeData.slice(0,8);
    const cityNames = top8Destinations.map(destination => destination.city);

    //Object to return
    const newResult = {
        results: newMergeData,
        top8Destinations: cityNames
    };

    //Need doc for modification
    const mergeRequestDoc = doc(db, `Groups/${request.groupId}/mergeRequests/${request.id}`);

    //Post data
    //If first to respond, create merge doc. If not, update existing doc
    if (isFirstResponder) {
        try {
            const mergeColRef = collection(db, "Merges");
            const mergeName = `${request.name}'s merge`;
            
            const mergeDocData = {
                groupId: request.groupId,
                name: mergeName,
                results: newMergeData,
                top8Destinations: cityNames,
                includedMembers: [userId, request.sentBy],
                createdAt: new Date()
            };

            const newMergeDoc = await addDoc(mergeColRef, mergeDocData);

            //Update mergeDocId in group merge request
            await updateDoc(mergeRequestDoc, {
                mergeDocId: newMergeDoc.id
            });
        } catch (error) {
            console.error("Error posting merge document:", error.message);
        }
    } else {
        try {
            const mergeDoc = doc(db, `Merges/${request.mergeDocId}`);

            await updateDoc(mergeDoc, {
                results: newMergeData,
                top8Destinations: cityNames,
                includedMembers: arrayUnion(userId) 
            });
        } catch (error) {
            console.error("Error getting group merge doc ")
        }
    }

    //Finally, update request doc with newly included member or delete if no members left to add
    /*
        Group length is number of members not including current user, so if 
        included members is equal to group length, they are the last to respond
    */
    if (request.includedMembers.length == request.groupLength) {
        try {
            await deleteDoc(mergeRequestDoc);
        } catch (error) {
            console.error("Error deleting merge request doc:", error.message);
        }
    } else {
        try {
            await updateDoc(mergeRequestDoc, {
                includedMembers: arrayUnion(userId)
            });
        } catch (error) {
            console.error("Error updating merge request doc:", error.message);
        }
    }

    return newResult;
};

export default performGroupSurveyMerge;