import { db } from "../../firebase";
import { doc, setDoc, deleteDoc } from "firebase/firestore";

const friendRequestResponse = async (userId, firstName, lastName, friendUserId, friendName, accept) => {
    try {
        const friendRequestReceivedDoc = doc(db, `Users/${userId}/friendRequestsReceived/${friendUserId}`);
        const friendRequestSentDoc = doc(db, `Users/${friendUserId}/friendRequestsSent/${userId}`);

        if (accept) {
            const inviteeDoc = doc(db, `Users/${userId}/friends/${friendUserId}`);
            const inviterDoc = doc(db, `Users/${friendUserId}/friends/${userId}`)
            await setDoc(inviteeDoc, {
                userId: friendUserId,
                name: friendName
            });

            await setDoc(inviterDoc, {
                userId: userId,
                name: `${firstName} ${lastName}`
            });
        }

        await deleteDoc(friendRequestReceivedDoc)
        await deleteDoc(friendRequestSentDoc);
    } catch (error) {
        console.error('Error handling friend request:', error);
    }
    
};

export default friendRequestResponse;