import { db } from '../../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';

const fetchResults = async (userId) => {
    try {
      // Fetch Pinned Results
      const pinnedResultsRef = collection(db, 'Results', userId, 'Pinned');
      const pinnedSnapshot = await getDocs(pinnedResultsRef);
      const pinnedData = pinnedSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch Past Results
      const pastResultsRef = collection(db, 'Results', userId, 'Game');
      const pastQuery = query(pastResultsRef, orderBy('Time', 'desc'));
      const pastSnapshot = await getDocs(pastQuery);
      const pastData = pastSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch Survey Results
      const surveyResultsRef = collection(db, 'Results', userId, 'Survey');
      const surveySnapshot = await getDocs(surveyResultsRef);
      const surveyData = surveySnapshot.docs.map(doc => ({ id:doc.id, ...doc.data() }));

      // Fetch Pinned Survey Results
      const pinnedSurveyRef = collection(db, 'Results', userId, 'SurveyPinned');
      const surveyPinnedSnap = await getDocs(pinnedSurveyRef);
      const pinnedSurveyData = surveyPinnedSnap.docs.map(doc => ({ id:doc.id, ...doc.data() }));

      return { pinnedData, pastData, surveyData, pinnedSurveyData };
    } catch (err) {
      console.error('Error fetching results:', err);
    }
};

export default fetchResults;