import styled from "styled-components";

export const ProfileStyles = styled.div`
.profile-container {
  max-width: 1000px;
  margin: 20px auto;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.profile-content {
  display: flex; 
  flex-direction: column; 
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.profile-info {
  display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
}

.profile-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-image {
  width: clamp(60px, 15%, 80px);
  height: clamp(60px, 15%, 80px);
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin-bottom: 10px;
  margin-right: 5px;
}

.profile-text {
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
}

h2 {
  font-size: 150%;
  margin: 0;
  font-weight: 500;
}

.username {
  color: #666;
  font-size: 0.9em;
  margin-top: 5px;
}

.edit-profile-button {
  margin-left: auto;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  width: 100px;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.save-button {
  padding: 8px 16px;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.cancel-button {
  padding: 8px 16px;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.results-content {
  width: 100%;
}

.results {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
}

.pinned-results, .past-results {
  flex: 1;
  width: 48%;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  max-height: 500px;
  overflow: auto;
}

.pinned-results h3, .past-results h3 {
  margin-top: 0;
  font-size: 1.2em;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1000px;
    margin: 20px auto;
}

.back-button {
    background: none;
    border: none;
    color: blue;
    font-size: 100%;
}

.merge-button {
    border: none;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    font-size: 18pt;
    font-family "Myriad Pro"
    align-self: flex-end;
    background-color: #009999;
    color: white;
}

.merge-icon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-bottom: 3px;
    filter: invert(1);
}

.unfriend-button {
    border: none;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    font-size: 12pt;
    font-family "Myriad Pro"
    align-self: flex-end;
    background-color: red;
    color: white;
    width: 100px;
    height: 40px;
    white-space: nowrap;
}

.unfriend-icon {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-bottom: 3px;
    filter: invert(1);
}

.save-result-icon {
  width: 25px;
  height: 25px;
  filter: invert(1);
  margin-left: 5px;
}

@media (max-width: 1052px) {
  .profile-container {
    margin: 0;
    padding: 0;
  }

  .banner-image {
    height: 150px;
  }
}

@media (max-width: 800px) {
  .results {
    flex-direction: column;
  }

  .pinned-results, .past-results {
    width: 100%;
  }

  .past-results {
    max-height: none;
  }

  .buttons-container {
    flex-direction: column;
  }
}
`;

export const ResultBox = styled.div`
background-color: #ffffff;
border: 1px solid #ddd;
border-radius: 8px;
padding: 20px;
margin-bottom: 15px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
width: 100%;

h4 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}
  
.pin-button {
    border: none;
    background-color: #ffffff;
}

img {
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
}

.heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.summary p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}

p {
  color: #666;
  margin: 0;
}

.see-more-button {
  background: none;
  border: none;
  color: blue;
  font-size: 75%;
}
`;