import { GroupDetailsStyles } from "./GroupDetailsStyles";
import mergeIcon from "../../Assets/Images/merge.png";
import { useEffect, useState } from "react";
import deleteFriendGroup from "./deleteFriendGroup";
import SelectResultsForMerge from "../merge-helpers/SelectResultsForMerge";
import createGroupMergeRequest from "../merge-helpers/createGroupMergeRequest";
import { ResultBox } from "../profile-helpers/profile-styles";
import performGroupMerge from "../merge-helpers/performGroupMerge";
import performGroupSurveyMerge from "../merge-helpers/performGroupSurveyMerge";

const FriendGroupDetails = ({ userId, group, deleteGroupDisplay, sendFriendRequest, onClose, viewGameMerge, viewSurveyMerge, removeMergeRequest }) => {
    const [isDeletingGroup, setIsDeletingGroup] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isSelectingType, setIsSelectingType] = useState(false);
    const [isSelectingResults, setIsSelectingResults] = useState(false);
    const [addedFriends, setAddedFriends] = useState({});
    const [mergeType, setMergeType] = useState(null);
    const [isViewingRequests, setIsViewingRequests] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);

    const removeMember = (memberId) => {
        try {

        } catch (error) {
            setError(error.message);
        }
    };

    const handleDeleteGroup = async () => {
        setLoading(true);
        setSuccess(false);
        setError(null);

        try {
            await deleteFriendGroup(group.id);
            setSuccess(true);
            deleteGroupDisplay(group.id);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const onMergeCancel = () => {
        setIsSelectingType(false);
        setIsSelectingResults(false);
        setSelectedRequest(null);
        setLoading(false);
        setError(null);
        setSuccess(false);
    };

    const handleMergeTypeSelect = (type) => {
        setMergeType(type);
        setIsSelectingResults(true);
        setIsSelectingType(false);
        setLoading(false);
        setError(null);
        setSuccess(false);
    }

    const handleViewMerge = (result) => {
        viewGameMerge(result.top8Destinations);
        removeMergeRequest(selectedRequest.id);
    };

    //Used for both selecting results for merge invite and response
    const handleResultSelect = async (result, subcollection) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        if (selectedRequest) {
            if (mergeType === "Game" || mergeType === "Pinned") {
                try {
                    const mergeResult = await performGroupMerge(userId, result, selectedRequest);
                    setSuccess(true);
                    handleViewMerge(mergeResult);
                } catch (error) {
                    setError(`Error performing merge: ${error.message}`);
                    setSuccess(false);
                } finally {
                    setLoading(false);
                }
            } else {
                try {
                    const mergeResult = await performGroupSurveyMerge(userId, result, selectedRequest);
                    setSuccess(true);
                    handleViewMerge(mergeResult);
                } catch (error) {
                    setError(`Error performing merge: ${error.message}`)
                } finally {
                    setLoading(false);
                }
            }
        } else {
            try {
                await createGroupMergeRequest(group, userId, result, subcollection);
                setSuccess(true);
            } catch (error) {
                setError("Error sending merge request:", error.message);
                setSuccess(false);
            } finally {
                setLoading(false);
            }
        };
    };

    const handleAddFriend = (memberId) => {
        sendFriendRequest(memberId);

        setAddedFriends((prev) => ({
            ...prev,
            [memberId]: true
        }));
    };

    const handleAddResults = (request) => {
        const formattedRequest = {
            ...request,
            groupRequestId: request.id,
            groupId: group.id,
            name: group.groupName,
            groupLength: Object.keys(group.members).length
        };
        setSelectedRequest(formattedRequest);
        setMergeType(request.subcollection);
        setIsSelectingResults(true);
        setIsViewingRequests(false);
    };

    if (isViewingRequests) {
        return (
            <GroupDetailsStyles>
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>{group.groupName}</h3>
                        <button className="close-button" onClick={() => setIsViewingRequests(false)}>x</button>
                        <h4 className="section-title" style={{ textAlign: "center", paddingTop: "20px" }}>Pending Merge Requests</h4>
                        <div className="members-list">
                        {group.requests.map((request) => (
                            <li key={request.id}>
                                <ResultBox>
                                    <div className="heading">
                                        <h4>Sent by: {group.members[request.sentBy]?.fullName || "Unknown User"}</h4>
                                    </div>
                                    <div className="summary">
                                    <p style={{ paddingTop: '5px' }}>
                                        Included Members:{" "}
                                        {request.includedMembers.map((memberId, index) => (
                                            <span key={memberId}>
                                                {group.members[memberId]?.fullName || "Unknown Member"}
                                                {index < request.includedMembers.length - 1 ? ", " : ""}
                                            </span>
                                        ))}
                                    </p>
                                    <button onClick={() => handleAddResults(request)} className='see-more-button'>
                                        Join Merge →
                                    </button>
                                    </div>
                                </ResultBox>
                            </li>
                        ))}
                        </div>
                    </div>
                </div>
            </GroupDetailsStyles>
        )
    }

    if (isSelectingType) {
        return (
            <GroupDetailsStyles>
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>{group.groupName}</h3>
                        <button className="close-button" onClick={() => onMergeCancel()}>x</button>
                        <h4 className="section-title" style={{ textAlign: "center", paddingTop: "20px" }}>Select merge type</h4>
                        <div className="buttons-container">
                            <button onClick={() => handleMergeTypeSelect("Game")} className="result-type-button">Game</button>
                            <button onClick={() => handleMergeTypeSelect("Survey")} className="result-type-button">Survey</button>
                        </div>
                    </div>
                </div>
            </GroupDetailsStyles>
        )
    }

    if (isSelectingResults) {
        return (
            <SelectResultsForMerge
                userId={userId}
                onClose={onMergeCancel}
                onSelect={handleResultSelect}
                subcollection={mergeType}
                loading={loading}
                error={error}
                success={success}
            />
        )
    }

    if (isDeletingGroup) {
        return (
            <GroupDetailsStyles>
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>{group.groupName}</h3>
                        <button className="close-button" onClick={onClose}>x</button>
                        <div className="message-section">
                            {loading ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>Error deleting group: {error}</p>
                            ) : success ? (
                                <p>Friend group deleted successfully.</p>
                            ) : (
                                <h4>Are you sure you want to delete this group?</h4>
                            )}
                        </div>
                        {loading || success || error ? (
                            <></>
                        ) : (
                            <div className="buttons-section">
                                <button className="cancel-button" onClick={() => setIsDeletingGroup(false)}>Cancel</button>
                                <button className="remove-button" onClick={() => handleDeleteGroup()}>Delete</button>
                            </div>
                        )}
                    </div>
                </div>
            </GroupDetailsStyles>
        )
    }

    return (
        <GroupDetailsStyles>
            <div className="modal-overlay">
                <div className="modal-content">
                    <h3>{group.groupName}</h3>
                    <button className="close-button" onClick={onClose}>x</button>
                    <div className="members-section">
                        <div className="header">
                            <h4 className="section-title">Members</h4>
                            {group.requests?.length > 0 && (
                                <span className="requests-bubble" onClick={() => setIsViewingRequests(true)}>
                                    {group.requests.length} new merge requests
                                    <span className="alert-icon">!</span>
                                </span>
                            )}
                        </div>
                        <ul className="members-list">
                            {Object.values(group.members).map((member) => (
                                <li key={member.userId} className="friend-object">
                                    <div className="friend-info">
                                        <img src={member.profileImage} className="friend-profile-photo" alt="profile-picture" />
                                        <div className="friend-identifiers">
                                            <h4>{member.fullName}</h4>
                                            <p>@{member.userName}</p>
                                        </div>
                                    </div>
                                    <div className="buttons-container">
                                        {group.createdBy === userId ? (
                                            <button className="remove-button" onClick={() => removeMember(member.userId)}>Remove</button>
                                        ) : member.isFriend ? (
                                            <button className="add-friend-button" disabled>Friends</button>
                                        ) : (
                                            <button
                                                className="add-friend-button"
                                                onClick={() => handleAddFriend(member.userId)}
                                                disabled={addedFriends[member.userId]} // Disable if already added
                                            >
                                                {addedFriends[member.userId] ? "Request sent" : "Add Friend"}
                                            </button>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="buttons-section">
                        <button className="merge-button" onClick={() => setIsSelectingType(true)}>
                            Group Merge
                            <img src={mergeIcon} className="merge-icon"/>
                        </button>
                        <button 
                            className="delete-group-button" 
                            onClick={() => setIsDeletingGroup(true)}
                            disabled={group.createdBy !== userId}
                            >
                            Delete Group
                        </button>
                    </div>
                </div>
            </div>
        </GroupDetailsStyles>
    )
};

export default FriendGroupDetails;