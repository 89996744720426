import { SurveyPopUpStyles } from "./survey-pop-up-styles";
import ship from "../../Assets/Images/ship.svg";
import island from "../../Assets/Images/island.svg";
import { useEffect, useState } from "react";
import { useUserAuth } from "../../UserAuthContext";
import Location from "../../Helpers/user-geo-location/get-user-location"
function SurveyPopUp(props) {
  const closeModal = () => {
    // This will close the modal and update the state
    switch (props.pageNum) {
      case 1:
        props.setShowModal(false);
        props.setPageNum(2);
        return;
      case 4:
        props.setShowModal(false);
        props.setPageNum(5);
        return;
      case 5:
        props.setShowModal(false);
        props.setPageNum(6);
        return;
      case 6:
        props.setShowModal(false);
        props.setPageNum(8);
        return;
      case 7:
        props.setShowModal(false);
        props.setPageNum(9);
        return;
      default:
        return;
    }
  };

  const ModalContents = () => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const { setUserEmail, userEmail, userName, setUserName } = useUserAuth();
    const [answeredEmail, setAnsweredEmail] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    function handleSubmit(event) {
      event.preventDefault();
      setUserEmail(email);
      setUserName(name);
    }

    useEffect(() => {
      if (userEmail) {
        setAnsweredEmail(true);
      }
    }, [userEmail]);

    function handleChange(event) {
      setEmail(event.target.value);
    }

    function handleName(event) {
      setName(event.target.value);
    }
    const [Continue, setContinue] = useState(true);
    const handleContinue = () => {
      setContinue(!Continue);
    };
    switch (props.pageNum) {
      case 1:
        return (
          <>
            <div className="pop-up-blurb-container">
              <p className="pop-up-blurb">
                We're just going to ask for a little bit of information from
                you.
              </p>
              <p className="pop-up-blurb">
                After that, we'll give you recommendations based on your
                answers.
              </p>
            </div>
            <button className="pop-up-continue" onClick={closeModal}>
              Okay!
            </button>
          </>
        );
      case 5:
        return (
          <>
          <Location/>
            <div className="pop-up-blurb-container">
              <p className="pop-up-blurb">Just a few more questions!</p>
            </div>
            <button className="pop-up-continue" onClick={closeModal}>
              Okay!
            </button>
          </>
        );

      case 7:
        if (!answeredEmail)
          return (
            <>
              <div className="pop-up-blurb-container">
                <p className="pop-up-blurb" style={{ width: "90%" }}>
                  Have your results emailed to you!<br></br>By clicking 'Okay'
                  and opting in to the Nina newsletter you'll be kept in the
                  know for updates and exclusives.
                </p>
                <form
                  onSubmit={handleSubmit}
                  className="d-flex flex-column w-100"
                >
                  <div className="w-100 mt-4">
                    <input
                      type="text"
                      id="name-input"
                      lang=";78"
                      value={name}
                      onChange={handleName}
                      required
                      placeholder="Name"
                      style={{
                        borderRadius: "5px",
                        borderStyle: "solid",
                        width: "300px",
                      }}
                    />
                  </div>
                  <div className="w-100 mt-4">
                    <input
                      type="email"
                      id="email-input"
                      lang=";78"
                      value={email}
                      onChange={handleChange}
                      required
                      placeholder="Email"
                      style={{
                        borderRadius: "5px",
                        borderStyle: "solid",
                        width: "300px",
                      }}
                    />
                  </div>
                  <div className="d-flex text-center justify-content-evenly">
                    <button type="submit" className="pop-up-option">
                      Okay
                    </button>

                    <button
                      className="pop-up-option"
                      onClick={() => {
                        setUserEmail("");
                        setUserName("");
                        setAnsweredEmail(true);
                      }}
                    >
                      No thanks
                    </button>
                  </div>
                </form>
              </div>
            </>
          );
        else if ((userEmail && userName) !== "" && confirmation === false) {
          return (
            <>
              <div className="pop-up-blurb-container">
                <p className="pop-up-blurb">
                  Thank you!<br></br>Your results will be delivered to your
                  email.
                </p>
              </div>
              <button
                className="pop-up-continue"
                onClick={() => {
                  setConfirmation(true);
                }}
              >
                Continue
              </button>
            </>
          );
        } else
          return (
            <>
              <div className="pop-up-blurb-container">
                {Continue ? (
                  <p className="pop-up-blurb">Finding the best trip now!</p>
                ) : (
                  <p className="pop-up-blurb">
                    Finished! Check out these trips!
                  </p>
                )}
                <div className="animation-wrapper">
                  <div className="animation-container">
                    <img
                      className={
                        Continue
                          ? "animation-component animation-component-start"
                          : "animation-component animation-component-finish"
                      }
                      src={ship}
                      width={"100px"}
                      alt="ship"
                    />
                    <img
                      className="animation-component animation-component-island"
                      src={island}
                      width={"100px"}
                      alt="island"
                    />
                  </div>
                </div>
              </div>
              {Continue ? (
                <button className="pop-up-continue" onClick={handleContinue}>
                  Continue
                </button>
              ) : (
                <button className="pop-up-finish" onClick={closeModal}>
                  Finish
                </button>
              )}
            </>
          );

      default:
        return;
    }
  };

  return (
    <SurveyPopUpStyles>
      <div className="popup">
        <div className="pop-up-container">
          <div className="pop-up-line"></div>
          <ModalContents />
          <div className="pop-up-line"></div>
        </div>
      </div>
    </SurveyPopUpStyles>
  );
}

export default SurveyPopUp;
