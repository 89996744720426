import { useEffect, useState, useRef } from "react";
import { SearchUsersStyles } from "./SearchUsersStyles";
import { fetchFriendRequestsSent } from "./fetchFriendRequests";
import SearchUsers from "./SearchUsers";
import magnifyingGlass from "../../Assets/Images/magnifying-glass.png";
import checkmarkIcon from "../../Assets/Images/checkmarkIcon.png";
import xIcon from "../../Assets/Images/xIcon.png";
import { SearchBarStyles } from "../profile-helpers/SearchBarStyles";
import { useUserAuth } from "../../UserAuthContext";
import sendFriendRequest from "./sendFriendRequest";
import fetchUserProfile from "./fetchUserProfile";
import friendRequestResponse from "./friendRequestResponse";

const SearchUsersModal = ({onClose, currentFriends, setCurrentFriends, friendRequestsReceived, setFriendRequestsReceived}) => {
    const { user, userName, firstName, lastName } = useUserAuth();
    const [currentFriendsIds, setCurrentFriendsIds] = useState([]);
    const [friendRequestsSentIds, setFriendRequestsSentIds] = useState([]);
    const [friendRequestsReceivedIds, setFriendRequestsReceivedIds] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const resultsRef = useRef(null);

    useEffect(() => {
        //Extract only the IDs from the currentFriends array
        if (currentFriends && currentFriends.length > 0) {
            const ids = currentFriends.map(friend => friend.userId); 
            setCurrentFriendsIds(ids);
        }

        if (friendRequestsReceived && friendRequestsReceived.length > 0) {
            const ids = friendRequestsReceived.map(request => request.id);

            setFriendRequestsReceivedIds(ids);
        }

        const fetchFriendRequests = async () => {
            const friendRequestsSent = await fetchFriendRequestsSent(user.uid);
            const friendRequestsSentIds = friendRequestsSent.map(request => request.id);
            setFriendRequestsSentIds(friendRequestsSentIds);
        }

        if (user.uid) {
            fetchFriendRequests();
        }

    }, [currentFriends])

    //Hide search results when clicking outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (resultsRef.current && !resultsRef.current.contains(e.target)) {
                setSearchResults([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value === "") {
            setSearchResults([]);
        }
    };

    const handleSearch = async () => {
        if (searchQuery.trim() === "") return;
    
        const results = await SearchUsers(searchQuery);

        const filteredResults = results.filter(result => result.id !== user.uid);
        setSearchResults(filteredResults);
    };

    const handleSendRequest = async (selectedUserId) => {
        try {    
            await sendFriendRequest(user.uid, selectedUserId, firstName, lastName, userName);
            setFriendRequestsSentIds((prevIds) => [...prevIds, selectedUserId]);
            setSearchResults([]);
        } catch (error) {
            alert('Failed to send friend request.');
        }
    };

    const handleFriendRequestResponse = async (friendUserId, friendName, accept) => {
        try {
            setFriendRequestsReceivedIds(prevRequests =>
                prevRequests.filter(request => request.id !== friendUserId)
            );

            setFriendRequestsReceived(prevRequests =>
                prevRequests.filter(request => request.id !== friendUserId)
            );

            if (accept) {
                const newFriendInfo = await fetchUserProfile(friendUserId);
                const newFriend = {
                    name: friendName,
                    ...newFriendInfo
                };

                setCurrentFriends(prevFriends => [...prevFriends, newFriend])
                setCurrentFriendsIds(prevIds => [...prevIds, friendUserId]);
            }

            await friendRequestResponse(user.uid, firstName, lastName, friendUserId, friendName, accept)
            
            setSearchResults([]);
        } catch (error) {
            alert('Failed to handle friend request.');
        }
    };

    return (
        <SearchUsersStyles>
            <div className="modal-overlay">
                <div className="modal-content">
                    <h3>Search Users</h3>
                    <button className="close-button" onClick={onClose}>x</button>

                    <SearchBarStyles>
                        <div className="search-container">
                            <div className="search-bar-container">
                                <input
                                    className="friend-search-bar"
                                    type="text"
                                    placeholder="Search for friends by name, username, or email"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch();
                                        }
                                    }}
                                />
                                <button onClick={handleSearch} className="search-button">
                                    <img src={magnifyingGlass} className='search-icon' alt="Search" />
                                </button>
                            </div>
                            <div className="search-results" ref={resultsRef}>
                                {searchResults.length > 0 && (
                                    <ul>
                                        {searchResults.map((result) => (
                                            <li key={result.id} className="search-item">
                                                <span>{result.firstName} {result.lastName} (@{result.userName})</span>

                                                {/* Conditionally render button based on friend status */}
                                                {currentFriendsIds.includes(result.id) ? (
                                                    <span>Current Friend</span>
                                                ) : friendRequestsSentIds.includes(result.id) ? (
                                                    <button className="request-sent-button" disabled>Request Sent</button> 
                                                ) : friendRequestsReceivedIds.includes(result.id) ? (
                                                    <div className="buttons-container">
                                                        <button className="accept-button">
                                                            <img src={checkmarkIcon} className="checkmark-icon" onClick={() => handleFriendRequestResponse(result.id, `{${result.firstName} ${result.lastName}}`, true)} alt="check"/>
                                                        </button>
                                                        <button className="decline-button">
                                                            <img src={xIcon} className="x-icon" onClick={() => handleFriendRequestResponse(result.id, `{${result.firstName} ${result.lastName}}`, false)} alt="x"/>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <button className="add-friend-button" onClick={() => handleSendRequest(result.id)}>Request</button> // Default add friend button
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </SearchBarStyles>
                </div>
            </div>
        </SearchUsersStyles>
    )
}

export default SearchUsersModal;