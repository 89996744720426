import { LearnMoreStyles } from "./LearnMoreStyles";
import friendsIcon from "../../Assets/Images/friendsImage.png";
import downloadIcon from "../../Assets/Images/saveResult.png";
import mergeIcon from "../../Assets/Images/merge.png";
import gameIcon from "../../Assets/Images/gameIcon.png";
import surveyIcon from "../../Assets/Images/surveyIcon.png";
import { useNavigate } from "react-router-dom";

const LearnMore = () => {
    const navigate = useNavigate();

    return (
        <LearnMoreStyles>
            <div>
                <div className="hero">
                    <div className="text-container">
                        <h1>How It Works</h1>
                        <p>Discover how our destination suggestion algorithms and account features can enhance your travel planning.</p>
                    </div>
                    <button className="sign-up-button" onClick={() => navigate("/profile-dashboard")}>Sign Up Now</button>
                </div>
                <div className="info-section">
                    <h2>Smart Destination Suggestions</h2>
                    <p>Our algorithms use your preferences to recommend the perfect destinations. Choose between:</p>
                    <div className="features">
                        <div className="feature">
                            <img src={gameIcon} alt="Game Icon" />
                            <div>
                                <h3>Destination Game</h3>
                                <p>Choose between photos to hone in your preference.</p>
                            </div>
                            <button className="cta-button" onClick={() => navigate("/game")}>Play the Game</button>
                        </div>
                        <div className="feature">
                            <img src={surveyIcon} alt="Survey Icon" />
                            <div>
                                <h3>Discover Survey</h3>
                                <p>Complete a quick survey for tailored recommendations.</p>
                            </div>
                            <button className="cta-button" onClick={() => navigate("/survey")}>Take the Survey</button>
                        </div>
                    </div>
                </div>
                <div className="info-section" id="black">
                    <h2>Why Create an Account?</h2>
                    <p>Unlock these exclusive features:</p>
                    <div className="benefits">
                        <div className="benefit">
                            <img src={downloadIcon} alt="Save Icon" />
                            <div>
                                <h3>Save Your Results</h3>
                                <p>Access your destination suggestions anytime.</p>
                            </div>
                        </div>
                        <div className="benefit">
                            <img src={mergeIcon} alt="Friends Icon" />
                            <div>
                                <h3>Merge Results</h3>
                                <p>Add friends, use our merge feature, and get combined results.</p>
                            </div>
                        </div>
                        <div className="benefit">
                            <img src={friendsIcon} alt="Groups Icon" />
                            <div>
                                <h3>Create Groups</h3>
                                <p>Add your group trip members and merge with everyone!</p>
                            </div> 
                        </div>
                    </div>
                    <button className="cta-button" onClick={() => navigate("/profile-dashboard")}>Create Account</button>
                </div>
                <div className="final-cta">
                    <h2>Ready to Start Your Journey?</h2>
                    <p>Sign up today and unlock personalized travel recommendations.</p>
                    <button className="cta-button" onClick={() => navigate("/profile-dashboard")}>Sign Up Now</button>
                </div>
            </div>
        </LearnMoreStyles>
    );
};

export default LearnMore;