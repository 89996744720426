import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUserAuth } from "../../UserAuthContext";
import changeUserName from "../../Helpers/profile-helpers/changeUserName";
import fetchCurrentFriends from "../../Helpers/friends/fetchCurrentFriends";
import fetchUserProfile from "../../Helpers/friends/fetchUserProfile";
import fetchFriendGroups from "../../Helpers/friends/fetchFriendGroups";
import sendFriendRequest from "../../Helpers/friends/sendFriendRequest";
import fetchResults from "../../Helpers/results/fetchResults";
import pinResult from "../../Helpers/results/pinResult";
import unpinResult from "../../Helpers/results/unpinResult";
import fetchGroupResults from "../../Helpers/merge-helpers/fetchGroupResults";
import fetchMergeResults from "../../Helpers/merge-helpers/fetchMergeResults";
import { DestinationRankings } from "../../Helpers/survey-helpers/suggestion-algorithm/suggestion-data";
import createFriendGroup from "../../Helpers/friends/createFriendGroup";
import { fetchFriendRequestsReceived } from "../../Helpers/friends/fetchFriendRequests";
import { ProfileStyles } from '../../Helpers/profile-helpers/profile-styles';
import DisplayResults from '../../Helpers/game-helpers/DisplayResults';
import FriendProfileView from '../../Helpers/friends/FriendProfileView';
import SignInSignUpModal from '../../Modals/survey/signInSignUpModal';
import SignOut from '../../Helpers/profile-helpers/SignOut';
import Modal from "../../Modals/survey/Modal";
import FriendGroupDetails from "../../Helpers/friends/FriendGroupDetails";
import SearchUsersModal from "../../Helpers/friends/SearchUsersModal";
import SelectFriendsForGroup from "../../Helpers/friends/SelectFriendsForGroup";
import FriendRequestModal from "../../Helpers/friends/FriendRequestModal";


const DashboardContext = createContext();
export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children, startCollapsed }) => {
    //States
    const { user, userName, firstName, lastName, profileImage, updateUsername } = useUserAuth();
    const [isEditing, setIsEditing] = useState(false);
    const [newUsername, setNewUsername] = useState(userName);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [currentFriends, setCurrentFriends] = useState([]);
    const [friendGroups, setFriendGroups] = useState([]);
    const [isGroupViewModalOpen, setIsGroupViewModalOpen] = useState(false);
    const [isGroupCreateModalOpen, setIsGroupCreateModalOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [friendRequestsSentIds, setFriendRequestsSentIds] = useState([]);
    const [friendRequestsReceived, setFriendRequestsReceived] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [pinnedGameResults, setPinnedGameResults] = useState([]);
    const [pastGameResults, setPastGameResults] = useState([]);
    const [surveyResults, setSurveyResults] = useState([]);
    const [pinnedSurveyResults, setPinnedSurveyResults] = useState([]);
    const [editingResultId, setEditingResultId] = useState(null);
    const [newResultId, setNewResultId] = useState("");
    const [isFriendCollapsed, setIsFriendCollapsed] = useState({});
    const [mergeResults, setMergeResults] = useState([]);
    const [groupResults, setGroupResults] = useState([]);
    const [isGroupCollapsed, setIsGroupCollapsed] = useState({});
    const [viewingResults, setViewingResults] = useState(false);
    const [selectedResult, setSelectedResult] = useState(null);
    const [isViewingFriend, setIsViewingFriend] = useState(false);
    const [selectedFriend, setSelectedFriend] = useState(null);
    const [showBackButton, setShowBackButton] = useState(true);
    const [searchUsersModalOpen, setSearchUsersModalOpen] = useState(false);
    const [isFriendRequestModalOpen, setIsFriendRequestModalOpen] = useState(false);
    const [isSigningOut, setIsSigningOut] = useState(false);

    
    //Fetch necessary data
    useEffect(() => {
        if (!user) return;

        const fetchFriendsAndGroups = async () => {
            const friends = await fetchCurrentFriends(user.uid);
        
            // Fetch additional information for each friend
            const detailedFriends = await Promise.all(friends.map(async friend => {
                const profile = await fetchUserProfile(friend.userId);
                return {
                    ...friend,
                    ...profile,
                };
            }));
        
            setCurrentFriends(detailedFriends);
        
            // Fetch the friend groups
            const groups = await fetchFriendGroups(user.uid);
        
            // Enrich the groups with additional member info
            const enrichedGroups = await Promise.all(groups.map(async (group) => {
                // Collect user profile fetch promises for members not in the friends list
                const enrichedMembers = await Promise.all(
                    Object.keys(group.members)
                        .filter(userId => userId !== user.uid) // Exclude the current user
                        .map(async (userId) => {
                            let memberData;
        
                            // Check if the member is in the detailedFriends list
                            const friend = detailedFriends.find(friend => friend.userId === userId);
        
                            if (friend) {
                                memberData = {
                                    fullName: group.members[userId],
                                    profileImage: friend.profileImage,
                                    userName: friend.userName,
                                    isFriend: true,
                                };
                            } else {
                                // If not in friends list, fetch the user's profile info
                                const profile = await fetchUserProfile(userId);
                                memberData = {
                                    fullName: group.members[userId],
                                    profileImage: profile.profileImage,
                                    userName: profile.userName,
                                    isFriend: false,
                                };
                            }
        
                            return { userId, ...memberData };
                        })
                );
        
                // Convert the array of enriched members into an object with userId keys
                const membersObject = enrichedMembers.reduce((acc, member) => {
                    acc[member.userId] = member;
                    return acc;
                }, {});
        
                return {
                    ...group,
                    members: membersObject,
                };
            }));
        
            setFriendGroups(enrichedGroups);
        };

        const fetchGameResults = async () => {
            try {
                const { pinnedData, pastData, surveyData, pinnedSurveyData } = await fetchResults(user.uid);
                setPinnedGameResults(pinnedData);
                setPastGameResults(pastData);
                setSurveyResults(surveyData);
                setPinnedSurveyResults(pinnedSurveyData);
            } catch (err) {
                console.error("Error loading results:", err.message);
            }
        }

        const fetchMergeData = async () => {
            const mergeResultsData = await fetchMergeResults(user.uid);
            
            // Group results by friend (userId)
            const groupedResults = mergeResultsData.reduce((acc, mergeDoc) => {
                mergeDoc.userIds.forEach((userId) => {
                    if (userId !== user.uid) { // Exclude the current user
                        if (!acc[userId]) {
                            acc[userId] = {
                                friendName: mergeDoc.userNames[userId],
                                name: mergeDoc.name,
                                mergedAttributeTotals: [],
                                top8Destinations: [],
                            };
                        }
                        // Merge `mergedAttributeTotals` and `top8Destinations`
                        acc[userId].mergedAttributeTotals.push(mergeDoc.mergedAttributeTotals);
                        acc[userId].top8Destinations.push(mergeDoc.top8Destinations);
                    }
                });
                return acc;
            }, {});

            setMergeResults(groupedResults);

            // Set all groups to be closed for mobile and open for desktop
            const initialCollapseState = {};
            Object.keys(groupedResults).forEach(userId => {
                initialCollapseState[userId] = startCollapsed;
            });
            setIsFriendCollapsed(initialCollapseState);

            //Group merge results
            const groupResults = await fetchGroupResults(user.uid);

            const groupedResultsByGroup = groupResults.reduce((acc, mergeDoc) => {
                const groupId = mergeDoc.groupId;
                if (!acc[groupId]) {
                    acc[groupId] = {
                        groupName: mergeDoc.name.replace(/'s merge$/, '').trim(),
                        mergeName: mergeDoc.name,
                        mergedAttributeTotals: [],
                        top8Destinations: [],
                        includedMembers: mergeDoc.includedMembers,
                    };
                }
        
                acc[groupId].mergedAttributeTotals.push(mergeDoc.attributeTotals);
                acc[groupId].top8Destinations.push(mergeDoc.top8Destinations);
        
                return acc;
            }, {});

            setGroupResults(groupedResultsByGroup);
            
            //Set group initial collapse state
            const initialCollapseStateByGroup = {};
            Object.keys(groupedResultsByGroup).forEach(groupId => {
                initialCollapseStateByGroup[groupId] = startCollapsed;
            });
            setIsGroupCollapsed(initialCollapseStateByGroup);
        };

        const fetchFriendRequests = async () => {
            const friendRequests = await fetchFriendRequestsReceived(user.uid);
            setFriendRequestsReceived(friendRequests);
        };

        if (user.uid) {
            fetchFriendsAndGroups();
            fetchGameResults();
            fetchMergeData(); //fetch merge results
            fetchFriendRequests();
        }
    }, [user]);

    //All functions necessary for dashboard actions
    const handleEditClick = () => {
        setError(null);
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        try {
          await changeUserName(user.uid, newUsername);
          updateUsername(newUsername);
          setIsEditing(false);
        } catch (err) {
          setError(err.message);
        }
    };

    const handleCancelClick = () => {
        setNewUsername(userName);
        setIsEditing(false);
    };

    const handleViewGroup = (group, members) => {
        setSelectedGroup(group);
        setIsGroupViewModalOpen(true);
    };

    const deleteGroupDisplay = (groupId) => {
        const updatedGroups = friendGroups.filter(group => group.id !== groupId);
        setFriendGroups(updatedGroups);
    }

    const handleSendRequest = async (selectedUserId) => {
        try {    
            await sendFriendRequest(user.uid, selectedUserId, firstName, lastName, userName);
            setFriendRequestsSentIds((prevIds) => [...prevIds, selectedUserId]);
            setSearchResults([]);
        } catch (error) {
            console.error('Failed to send friend request:', error.message);
            alert('Failed to send friend request.');
        }
    };

    const onClose = () => {
        setIsGroupViewModalOpen(false);
        setIsGroupCreateModalOpen(false);
        setSearchUsersModalOpen(false);
        setIsFriendRequestModalOpen(false);
        setSuccess(false);
        setLoading(false);
        setError(null);
    };

    const handleResultIdClick = (resultId) => {
        setEditingResultId(resultId);
        setNewResultId(resultId);
    };

    const handleResultIdChange = (e) => {
        setNewResultId(e.target.value);
    };

    const handleResultIdSave = async (originalId, isPinned) => {
        //await changeResultId(user.uid, originalId, newResultId, isPinned);
        setEditingResultId(null);
    };

    const handlePinClick = (result, pin) => {
        if (pin) {
            pinResult(user.uid, result);
            if (result.attributeTotals) {
                setPastGameResults(prevResults => prevResults.filter(r => r.id !== result.id));
                setPinnedGameResults(prevPinned => [...prevPinned, result]);
            } else {
                setSurveyResults(prevResults => prevResults.filter(r => r.id !== result.id));
                setPinnedSurveyResults(prevPinned => [...prevPinned, result]);
            }
        } else {
            unpinResult(user.uid, result);
            if (result.attributeTotals) {
                setPinnedGameResults(prevPinned => prevPinned.filter(r => r.id !== result.id));
                setPastGameResults(prevResults => {
                    // Insert back into the correct position using the Time field
                    const newResults = [...prevResults, result];
                    return newResults.sort((a, b) => new Date(b.Time) - new Date(a.Time));
                });
            } else {
                setPinnedSurveyResults(prevPinned => prevPinned.filter(r => r.id !== result.id));
                setSurveyResults(prevResults => {
                    const newResults = [...prevResults, result];
                    return newResults.sort((a, b) => new Date(b.time) - new Date(a.time));
                })
            }
        }
    };

    const toggleFriendCollapse = (id) => {
        setIsFriendCollapsed((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const toggleGroupCollapse = (groupId) => {
        setIsGroupCollapsed((prevState) => ({
            ...prevState,
            [groupId]: !prevState[groupId],
        }));
    };

    const handleViewGameResults = (result, subcollection) => {
        // Map the city names to their corresponding data from DestinationRankings
        const enrichedRankings = result.top8Destinations.map(city => ({
          city,
          ...DestinationRankings[city]
        }));
    
        setSelectedResult({
          id: result.id,
          rankings: enrichedRankings,
          subcollection: subcollection
        });
        setViewingResults(true);
    };

    const handleViewMergeResults = (top8Destinations) => {
        // Map the city names to their corresponding data from DestinationRankings
        const enrichedRankings = top8Destinations.map(city => ({
          city,
          ...DestinationRankings[city]
        }));
    
        setSelectedResult({
          rankings: enrichedRankings,
        });
        setViewingResults(true);
    };

    const handleViewSurveyResults = (result, subcollection) => {
        const top8Destinations = result.results.slice(0,8);
        const cityNames = top8Destinations.map(destination => destination.city);

        const enrichedRankings = cityNames.map(city => ({
          city,
          ...DestinationRankings[city]
        }));
    
        setSelectedResult({
          id: result.id,
          rankings: enrichedRankings,
          subcollection: subcollection
        });
        setViewingResults(true);
    };

    const handleCreateGroup = async (selectedFriends, groupName) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const newGroup = await createFriendGroup(user.uid, firstName, lastName, selectedFriends, groupName, friendGroups);
            setSuccess(true);
            setFriendGroups(prevGroups => [...prevGroups, newGroup]);
        } catch (error) {
            setError(`Failed to create friend group: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleViewFriend = async (friend) => {
        setSelectedFriend(friend);
        setIsViewingFriend(true);
    };
    
    const handleFilterMergeRequest = (requestId) => {
        //Delete request from selected group object
        setFriendGroups(prevGroups =>
            prevGroups.map(group => 
                group.id === selectedGroup.id ? { 
                    ...group, 
                    requests: group.requests.filter(request => request.id !== requestId)
                } : group
            )
        );
    };

    //Shared conditional rendering logic
    const renderProfileContent = () => {
        if (viewingResults) {
            return (
                <ProfileStyles>
                    <div>
                        <div className="header">
                            <button onClick={() => setViewingResults(false)} className="back-button">
                                ← Back
                            </button>
                        </div>
                        <div className="profile-container">
                            <DisplayResults
                                rankings={selectedResult.rankings}
                                DestinationRankings={DestinationRankings}
                            />
                        </div>
                    </div>
                </ProfileStyles>
            );
        }

        if (isViewingFriend) {
            return (
                <div>
                    <ProfileStyles>
                        {showBackButton && (
                            <div className="header">
                                <button onClick={() => setIsViewingFriend(false)} className="back-button">
                                    ← Back
                                </button>
                            </div>
                        )}
                        <div className="profile-container">
                            <FriendProfileView
                                friend={selectedFriend}
                                userId={user.uid}
                                firstName={firstName}
                                lastName={lastName}
                                setShowBackButton={setShowBackButton}
                                setCurrentFriends={setCurrentFriends}
                                setIsViewingFriend={setIsViewingFriend}
                            />
                        </div>
                    </ProfileStyles>
                </div>
            );
        }

        if (isSigningOut) {
            return (
                <ProfileStyles>
                    <div className="header">
                        <button onClick={() => setIsSigningOut(false)} className="back-button">
                            ← Back
                        </button>
                    </div>
                    <div className="sign-out-container">
                        <SignOut style={{ background: 'none' }} />
                    </div>
                </ProfileStyles>
            );
        }

        return null;
    };

    //Shared modal rendering logic
    const renderModals = () => {
        return (
            <>
                {/* Group View Modal */}
                <Modal isOpen={isGroupViewModalOpen} onClose={() => setIsGroupViewModalOpen(false)}>
                    <FriendGroupDetails
                        userId={user.uid}
                        group={selectedGroup}
                        deleteGroupDisplay={deleteGroupDisplay}
                        sendFriendRequest={handleSendRequest}
                        onClose={onClose}
                        viewGameMerge={handleViewMergeResults}
                        viewSurveyMerge={handleViewSurveyResults}
                        removeMergeRequest={handleFilterMergeRequest}
                    />
                </Modal>

                {/* Friend Group Creation Modal */}
                <Modal isOpen={isGroupCreateModalOpen} onClose={() => setIsGroupCreateModalOpen(false)}>
                    <SelectFriendsForGroup
                        currentFriends={currentFriends}
                        onClose={onClose}
                        onSubmit={handleCreateGroup}
                        loading={loading}
                        error={error}
                        success={success}
                    />
                </Modal>

                {/* Search Users Modal */}
                <Modal isOpen={searchUsersModalOpen} onClose={() => setSearchUsersModalOpen(false)}>
                    <SearchUsersModal
                        onClose={onClose}
                        currentFriends={currentFriends}
                        setCurrentFriends={setCurrentFriends}
                        friendRequestsReceived={friendRequestsReceived}
                        setFriendRequestsReceived={setFriendRequestsReceived}
                    />
                </Modal>

                {/* Friend requests modal */}
                <Modal isOpen={isFriendRequestModalOpen} onClose={() => setIsFriendRequestModalOpen(false)}>
                    <FriendRequestModal
                        friendRequests={friendRequestsReceived}
                        userId={user.uid}
                        firstName={firstName}
                        lastName={lastName}
                        setCurrentFriends={setCurrentFriends}
                        setFriendRequests={setFriendRequestsReceived}
                        onClose={() => setIsFriendRequestModalOpen(false)}
                    />
                </Modal>
            </>
        );
    }

    return (
        <DashboardContext.Provider value={{
            user, userName, firstName, lastName, profileImage,
            isEditing, newUsername, setNewUsername, error,
            currentFriends, friendGroups, friendRequestsReceived,
            setIsGroupCreateModalOpen, setSearchUsersModalOpen, setIsFriendRequestModalOpen,
            pinnedGameResults, pastGameResults, surveyResults, pinnedSurveyResults,
            editingResultId, newResultId,
            isFriendCollapsed, isGroupCollapsed,
            mergeResults, groupResults,
            setIsSigningOut,
            handleEditClick, handleSaveClick, handleCancelClick,
            handleViewGroup,
            handleResultIdChange, handleResultIdClick, handleResultIdSave,
            handlePinClick, toggleFriendCollapse, toggleGroupCollapse,
            handleViewGameResults, handleViewMergeResults, handleViewSurveyResults,
            handleViewFriend
        }}>
          {renderModals()}
          {renderProfileContent() || children}
        </DashboardContext.Provider>
      );
};