import styled from "styled-components";

export const NewHomeStyles = styled.div`
.hero {
    position: relative;
    width: 100%;
    height: 55vh;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 40px;
    color: white;
}
.hero video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    transition: top 0.2s ease-out;
}
.hero-content {
    max-width: 600px;
    text-align: left;
}
.hero h1 {
    font-size: 48px;
    margin-bottom: 10px;
}
.hero p {
    font-size: 18px;
    color: #ddd;
}
.info-container {
    width: 100%;
    background-color: white;
    z-index: 1;
}
.info-section {
    padding: 100px 20px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: -40px;
    z-index: 1;
    position: relative;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5px 0;
    gap: 8vw;
}
.info-section h2 {
    font-size: 38px;
    margin-bottom: 10px;
}
.info-section p {
    font-size: 20px;
    color: #666;
}
.text-container {
    display: flex;
    flex-direction: column;
    margin: auto 0;
}
.buttons-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.results-button {
    border: none;
    background: linear-gradient(to right, rgb(0, 94, 132), rgb(0, 153, 153));
    border-radius: 15px;
    color: white;
    height: 45px;
    width: 110px;
}
#black {
    background-color: black;
    color: white;
}
#account-button {
    width: 150px;
    height: 40px;
}
.graphic-container {
    display: flex;
    align-items: center;
}
.info-section img {
    background: none;
    width: 150px;
    height: auto;
}
#account-image {
    filter: invert(1);
}

//Scroll slide effect
.info-section {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    will-change: opacity, transform; /* Optimize for animations */
}
.info-section.show {
    opacity: 1;
    transform: translateY(0);
}

//Title effect:
.hero-content {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s ease-out forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

//Button effects
.results-button {
    border: none;
    background: linear-gradient(to right, rgb(0, 94, 132), rgb(0, 153, 153));
    border-radius: 15px;
    color: white;
    height: 45px;
    width: 110px;
    transition: transform 0.2s ease, box-shadow 0.3s ease;
}
.results-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 153, 153, 0.3);
}

//Mobile styles
@media (max-width: 768px) {
    .hero {
        height: 40vh;
        padding: 20px;
    }

    .hero video {
        transition: none !important;  /* Disable transition */
        top: 0 !important;  /* Ensure no movement or zoom on mobile */
    }

    .hero h1 {
        font-size: 32px;
    }

    .hero p {
        font-size: 16px;
    }

    .info-section {
        padding: 50px 20px;
        height: auto;
        flex-direction: column;
        gap: 10px;
    }

    .info-section h2 {
        font-size: 28px;
        text-align: center;
    }

    .info-section p {
        font-size: 16px;
        text-align: center;
    }

    .graphic-container {
        order: -1;
        align-self: center;
    }

    .buttons-container {
        flex-direction: column;
        gap: 10px;
        padding: 0;
    }

    .results-button {
        width: 100%;
        height: 50px;
        font-size: 16px;
    }

    #account-button {
        width: 100%;
        height: 50px;
    }

    .info-section img {
        width: 100px;
        height: auto;
        margin-bottom: 5px;
    }
}

`;