import { doc, collection, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const PostGameData = async (userID, attributeTotals, selectionCounts, rankings) => {
    const date = new Date();
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const timestampDocId = `${year}.${month}.${day}-${hours}:${minutes}`;
    
    userID = userID || "Other";
    const userDocRef = doc(db, 'Results', userID);
    const gameResultsColRef = collection(userDocRef, 'Game');
    const resultDocRef = doc(gameResultsColRef, timestampDocId);

    const top8Destinations = rankings.slice(0, 8).map(destination => destination.city);

    try {
        await setDoc(resultDocRef, {
            attributeTotals: attributeTotals,
            selectionCounts: selectionCounts,
            top8Destinations: top8Destinations,
            Time: new Date().toISOString(),
        }, { merge: true }); // Merge to update the document if it already exists        
    } catch (error) {
        console.log("Error posting results", error.message);
        await setDoc(doc(db, 'Game', 'Error'), {
            Error: error.toString(),
            ErrorTime: new Date().toISOString(),
        });
    }
};

export default PostGameData;