import React, { useEffect, useState } from 'react';
import { useDashboard } from "./DashboardProvider";
import magnifyingGlass from "../../Assets/Images/magnifying-glass.png";
import pinIcon from "../../Assets/Images/pin.png";
import filledPinIcon from "../../Assets/Images/filledPin.png";
import { ResultBox } from "../../Helpers/profile-helpers/profile-styles";
import bannerImage1 from "../../Assets/Images/profileBanner1.jpg";
import { MobileDashboardStyles } from "./MobileDashboardStyles";

const MobileDashboard = () => {
    //States and functions from Dashboard context provider
    const {
        user, userName, firstName, lastName, profileImage,
        isEditing, newUsername, setNewUsername, error,
        currentFriends, friendGroups, friendRequestsReceived,
        setIsGroupCreateModalOpen, setSearchUsersModalOpen, setIsFriendRequestModalOpen,
        pinnedGameResults, pastGameResults, surveyResults, pinnedSurveyResults,
        editingResultId, newResultId,
        isFriendCollapsed, isGroupCollapsed,
        mergeResults, groupResults,
        setIsSigningOut,
        handleEditClick, handleSaveClick, handleCancelClick,
        handleViewGroup,
        handleResultIdChange, handleResultIdClick, handleResultIdSave,
        handlePinClick, toggleFriendCollapse, toggleGroupCollapse,
        handleViewGameResults, handleViewMergeResults, handleViewSurveyResults,
        handleViewFriend
    } = useDashboard();

    //Local state for stat buttons and content control
    const [totalMergeResults, setTotalMergeResults] = useState(0);
    const [activeTab, setActiveTab] = useState('friends');
    const [activeResultTab, setActiveResultTab] = useState('game');
    const [activeMergeTab, setActiveMergeTab] = useState('friends');

    //Get total result counts for stats on component mount
    useEffect(() => {
        if (mergeResults && groupResults) {
            let runningTotal = 0;

            Object.keys(mergeResults).forEach(key => {
                const friend = mergeResults[key];
                if (friend.top8Destinations) {
                    runningTotal += friend.top8Destinations.length;
                }
            });

            Object.keys(groupResults).forEach(key => {
                const group = groupResults[key];
                if (group.top8Destinations && Array.isArray(group.top8Destinations)) {
                    runningTotal += group.top8Destinations.length;
                }
            });

            setTotalMergeResults(runningTotal);
        };
    });

    const totalGroupRequests = friendGroups.reduce((sum, group) => sum + (group.requests?.length || 0), 0);

    return (
        <MobileDashboardStyles>
            <div className="dashboard-container">
                <img
                    src={bannerImage1}
                    className={`banner-image ${isEditing ? 'editable-banner' : ''}`}
                    alt="Profile Banner"
                />

                <div className='profile-content'>
                    <div className='profile-info'>
                        <div className='profile-left'>
                        {/* Editable Profile Image */}
                        <img
                            src={profileImage}
                            className={`profile-image ${isEditing ? 'editable-image' : ''}`}
                            alt="Profile"
                        />
                        <div className='profile-text'>
                            <h2>{firstName} {lastName}</h2>
                            {isEditing ? (
                              <div>
                                <input
                                    type="text"
                                    value={newUsername}
                                    onChange={(e) => setNewUsername(e.target.value)}
                                    className="username-input"
                                />
                                {error ? (
                                  <p>Error: {error}</p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                                <p className='username'>@{userName}</p>
                            )}
                        </div>
                        </div>
                        <div className='buttons-container'>
                            {isEditing ? (
                                <>
                                    <button onClick={handleCancelClick} className="cancel-button">Cancel</button>
                                    <button onClick={handleSaveClick} className="save-button">Save</button>
                                </>
                            ) : (
                                <button onClick={handleEditClick} className='edit-profile-button'>Edit Profile</button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="dashboard-content">
                    <div className="top-line">
                        <div className="stat-buttons">
                            <div className='stat-button-contianer'>
                                <div className={`stat-button ${activeTab === 'friends' ? 'active' : ''}`} onClick={() => setActiveTab('friends')} style={{ position: 'relative' }}>
                                    <h3>Friends</h3>
                                    <div className="stat-value">{currentFriends.length}</div>
                                    {friendRequestsReceived.length > 0 && (
                                        <span className="notification-bubble">{friendRequestsReceived.length}</span>
                                    )}
                                </div>
                            </div>
                            <div className='stat-button-container'>
                                <div className={`stat-button ${activeTab === 'groups' ? 'active' : ''}`} onClick={() => setActiveTab('groups')} style={{ position: 'relative' }}>
                                    <h3>Groups</h3>
                                    <div className="stat-value">{friendGroups.length}</div>
                                    {totalGroupRequests > 0 && (
                                        <span className="notification-bubble">{totalGroupRequests}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`stat-button ${activeTab === 'results' ? 'active' : ''}`} onClick={() => setActiveTab('results')}>
                                <h3>Results</h3>
                                <div className="stat-value">{pinnedGameResults.length + pastGameResults.length + surveyResults.length + pinnedSurveyResults.length}</div>
                            </div>
                            <div className={`stat-button ${activeTab === 'merges' ? 'active' : ''}`} onClick={() => setActiveTab('merges')}>
                                <h3>Merges</h3>
                                <div className="stat-value">{totalMergeResults}</div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-line">
                        {/* Friends */}
                        {activeTab === 'friends' && (
                            <>
                            {friendRequestsReceived.length > 0 && (
                                <div className='requests-container'>
                                    <span className="requests-bubble" onClick={() => setIsFriendRequestModalOpen(true)}>
                                        {friendRequestsReceived.length} new friend requests
                                        <span className="alert-icon">!</span>
                                    </span>
                                </div>
                                
                            )}
                            <div className='friends-content'>
                                <div className="friends-header">
                                    <h3>Friends</h3>
                                    <button
                                        onClick={() => setSearchUsersModalOpen(true)} 
                                        className="magnifying-glass-button"   
                                    >
                                        <img src={magnifyingGlass} className="magnifying-glass"/>
                                    </button>
                                </div>
                                <div className="list-container">
                                    {currentFriends.length > 0 ? (
                                        <ul>
                                            {currentFriends.map((friend) => (
                                                <li key={friend.userId} className="friend-object">
                                                    <div className="friend-info">
                                                        <img src={friend.profileImage} className="friend-pfp" alt="profile-picture" />
                                                        <div className="friend-identifiers">
                                                            <h4>{friend.name}</h4>
                                                            <p>@{friend.userName}</p>
                                                        </div>
                                                    </div>
                                                    <button className="friend-view-button" onClick={() => handleViewFriend(friend)}>View</button>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p className='empty-text'>No current friends</p>
                                    )}
                                </div>
                            </div>
                            </>
                        )}

                        {/* Groups */}
                        {activeTab === 'groups' && (
                            <div className='groups-content'>
                                <div className="friend-groups-header">
                                    <h3>Groups</h3>
                                    <button className="create-group-button" onClick={() => setIsGroupCreateModalOpen(true)}>
                                        +
                                    </button>
                                </div>
                                {friendGroups.length > 0 ? (
                                    <ul>
                                        {friendGroups.map((group) => {
                                            return (
                                                <li key={group.id} className="friend-object">
                                                    <div className="group-info">
                                                        <h4>{group.groupName}</h4>
                                                        <p>
                                                            Members: {Object.values(group.members).map((member, index, membersArray) => (
                                                                <span key={member.userId}>
                                                                    {member.fullName}
                                                                    {index < membersArray.length - 1 && ', '}
                                                                </span>
                                                            ))}
                                                        </p>
                                                    </div>
                                                    <button
                                                        className="friend-view-button"
                                                        onClick={() => handleViewGroup(group, Object.values(group.members))}
                                                    >
                                                        View
                                                        {group.requests?.length > 0 && (
                                                            <span className="notification-bubble">{group.requests.length}</span>
                                                        )}
                                                    </button>
                                                </li>
                                            );  
                                        })}
                                    </ul>
                                ) : (
                                    <p className="empty-text">No friend groups</p>
                                )}
                            </div>
                        )}

                        {/* Results */}
                        {activeTab === 'results' && (
                            <div className='results-content'>
                                <div className='result-tabs'>
                                    <button 
                                        className={`result-tab ${activeResultTab === 'game' ? 'active' : ''}`} 
                                        onClick={() => setActiveResultTab('game')}
                                    >
                                        Game
                                    </button>
                                    <button 
                                        className={`result-tab ${activeResultTab === 'survey' ? 'active' : ''}`} 
                                        onClick={() => setActiveResultTab('survey')}
                                    >
                                        Discover
                                    </button>
                                </div>
                                <div className='result-content'>
                                    {activeResultTab === 'game' && (
                                        <>
                                        <h3>Game Results</h3>
                                        <div className='list-container'>
                                            {(pastGameResults.length > 0 || pinnedGameResults.length > 0) ? (
                                            <ul>
                                                {/*Pinned Results */}
                                                {pinnedGameResults.map(result => (
                                                    <ResultBox key={result.id}>
                                                        <div className='heading'>
                                                            {editingResultId === result.id ? (
                                                                <input
                                                                    type="text"
                                                                    value={newResultId}
                                                                    onChange={handleResultIdChange}
                                                                    onBlur={() => handleResultIdSave(result.id, false)}
                                                                    className="result-id-input"
                                                                />
                                                            ) : (
                                                                <h4 onClick={() => handleResultIdClick(result.id)}>{result.id}</h4>
                                                            )}
                                                            <button onClick={() => handlePinClick(result, false)} className='pin-button'>
                                                                <img src={filledPinIcon} alt='pin' id='pinned'/>
                                                            </button>
                                                        </div>
                                                        <div className='summary'>
                                                            <p>Top Destination: {result.top8Destinations[0]}</p>
                                                            <button onClick={() => handleViewGameResults(result, "Game")} className='see-more-button'>
                                                                See More →
                                                            </button>
                                                        </div>
                                                    </ResultBox>
                                                ))}
        
                                                {/* Past Results */}
                                                {pastGameResults.map(result => (
                                                    <ResultBox key={result.id}>
                                                        <div className='heading'>
                                                            {editingResultId === result.id ? (
                                                                <input
                                                                    type="text"
                                                                    value={newResultId}
                                                                    onChange={handleResultIdChange}
                                                                    onBlur={() => handleResultIdSave(result.id, false)}
                                                                    className="result-id-input"
                                                                />
                                                            ) : (
                                                                <h4 onClick={() => handleResultIdClick(result.id)}>{result.id}</h4>
                                                            )}
                                                            <button onClick={() => handlePinClick(result, true)} className='pin-button'>
                                                                <img src={pinIcon} alt='pin'/>
                                                            </button>
                                                        </div>
                                                        <div className='summary'>
                                                            <p>Top Destination: {result.top8Destinations[0]}</p>
                                                            <button onClick={() => handleViewGameResults(result, "Game")} className='see-more-button'>
                                                                See More →
                                                            </button>
                                                        </div>
                                                    </ResultBox>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p className='empty-text'>No game results found.</p>
                                        )}
                                        </div>
                                        </>
                                    )}

                                    {activeResultTab === 'survey' && (
                                        <>
                                        <h3>Discover Results</h3>
                                        <div className='list-container'>
                                            {surveyResults.length > 0 || pinnedSurveyResults.length > 0 ? (
                                                <ul>
                                                    {pinnedSurveyResults.map(result => (
                                                        <ResultBox key={result.id}>
                                                            <div className='heading'>
                                                                {editingResultId === result.id ? (
                                                                    <input
                                                                        type="text"
                                                                        value={newResultId}
                                                                        onChange={handleResultIdChange}
                                                                        onBlur={() => handleResultIdSave(result.id, false)}
                                                                        className="result-id-input"
                                                                    />
                                                                ) : (
                                                                    <h4 onClick={() => handleResultIdClick(result.id)}>{result.name}</h4>
                                                                )}
                                                                <button onClick={() => handlePinClick(result, false)} className='pin-button'>
                                                                    <img src={filledPinIcon} alt='pin'/>
                                                                </button>
                                                            </div>
                                                            <div className='summary'>
                                                                <p>Top Destination: {result.results[0].city}</p>
                                                                <button onClick={() => handleViewSurveyResults(result, "SurveyPinned")} className='see-more-button'>
                                                                    See More →
                                                                </button>
                                                            </div>
                                                        </ResultBox>
                                                    ))}
                                                    {surveyResults.map(result => (
                                                        <ResultBox key={result.id}>
                                                            <div className='heading'>
                                                                {editingResultId === result.id ? (
                                                                    <input
                                                                        type="text"
                                                                        value={newResultId}
                                                                        onChange={handleResultIdChange}
                                                                        onBlur={() => handleResultIdSave(result.id, false)}
                                                                        className="result-id-input"
                                                                    />
                                                                ) : (
                                                                    <h4 onClick={() => handleResultIdClick(result.id)}>{result.name}</h4>
                                                                )}
                                                                <button onClick={() => handlePinClick(result, true)} className='pin-button'>
                                                                    <img src={pinIcon} alt='pin'/>
                                                                </button>
                                                            </div>
                                                            <div className='summary'>
                                                                <p>Top Destination: {result.results[0].city}</p>
                                                                <button onClick={() => handleViewSurveyResults(result, "Survey")} className='see-more-button'>
                                                                    See More →
                                                                </button>
                                                            </div>
                                                        </ResultBox>
                                                    ))}
                                                </ul>
                                            ): (
                                                <p>No discover results</p>
                                            )}
                                        </div>                                    
                                        </>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Merge Results */}
                        {activeTab === 'merges' && (
                            <div className='merges-content'>
                                <div className='result-tabs'>
                                    <button 
                                        className={`result-tab ${activeMergeTab === 'friends' ? 'active' : ''}`} 
                                        onClick={() => setActiveMergeTab('friends')}
                                    >
                                        Friends
                                    </button>
                                    <button 
                                        className={`result-tab ${activeMergeTab === 'groups' ? 'active' : ''}`} 
                                        onClick={() => setActiveMergeTab('groups')}
                                    >
                                        Groups
                                    </button>
                                </div>
                                <div className='result-content'>
                                    {activeMergeTab === 'friends' && (
                                        <>
                                        <h3>Merges with friends</h3>
                                        <div className='list-container'>
                                        {Object.keys(mergeResults).length > 0 ? (
                                            <ul>
                                                {Object.entries(mergeResults).map(([userId, userResults]) => (
                                                    <li key={userId}>
                                                        {/* Clickable heading to toggle section visibility */}
                                                        <h4
                                                            onClick={() => toggleFriendCollapse(userId)}
                                                            className="friend-group-heading"
                                                        >
                                                            <span>{userResults.friendName}</span> {/* Friend name on the left */}
                                                            <span>{isFriendCollapsed[userId] ? "▲" : "▼"}</span> {/* Arrow on the right */}
                                                        </h4>

                                                        {/* Conditionally display the friend's results */}
                                                        {!isFriendCollapsed[userId] && (
                                                            <div className="friend-results">
                                                                {userResults.mergedAttributeTotals.map((mergedTotal, index) => (
                                                                <ResultBox key={index}>
                                                                    <div className='heading'>
                                                                    <h4>{userResults.name} {index + 1}</h4>
                                                                    </div>
                                                                    <div className='summary'>
                                                                    <p>Top Destination: {userResults.top8Destinations[index][0]}</p>
                                                                    <button onClick={() => handleViewMergeResults(userResults.top8Destinations[index])} className='see-more-button'>
                                                                        See More →
                                                                    </button>
                                                                    </div>
                                                                </ResultBox>
                                                                ))}
                                                        </div>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p className='empty-text'>No merge results.</p>
                                        )}
                                        </div>
                                        </>
                                    )}
                                    {activeMergeTab === 'groups' && (
                                        <>
                                            <h3>Group Merges</h3>
                                            <div className="list-container">
                                                {Object.keys(groupResults).length > 0 ? (
                                                    <ul>
                                                        {Object.keys(groupResults).map((groupId) => {
                                                            const group = groupResults[groupId];
                    
                                                            return (
                                                                <li key={groupId}>
                                                                    {/* Clickable heading to toggle section visibility */}
                                                                    <h4
                                                                        onClick={() => toggleGroupCollapse(groupId)}
                                                                        className="friend-group-heading"
                                                                    >
                                                                        <span>{group.groupName}</span> {/* Group name on the left */}
                                                                        <span>{isGroupCollapsed[groupId] ? "▲" : "▼"}</span> {/* Arrow on the right */}
                                                                    </h4>
                                                        
                                                                    {/* Collapsible section */}
                                                                    {!isGroupCollapsed[groupId] && (
                                                                        <div className="friend-results">
                                                                            {group.mergedAttributeTotals.map((mergedTotal, index) => (
                                                                                <ResultBox key={index}>
                                                                                <div className='heading'>
                                                                                    <h4>{group.mergeName} {index + 1}</h4>
                                                                                </div>
                                                                                <div className='summary'>
                                                                                    <p>Top Destination: {group.top8Destinations[index][0]}</p>
                                                                                    <button onClick={() => handleViewMergeResults(group.top8Destinations[index])} className='see-more-button'>
                                                                                    See More →
                                                                                    </button>
                                                                                </div>
                                                                                </ResultBox>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                ) : (
                                                    <p className="empty-text">No group results.</p>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <button className="sign-out-button" onClick={() => setIsSigningOut(true)}>Sign out</button>
        </MobileDashboardStyles>
    );
};

export default MobileDashboard;