import { db } from "../../firebase";
import { deleteDoc, doc } from "firebase/firestore";

const unfriend = async (userId, friendUserId) => {
    try {
        const userFriendDocRef = doc(db, `Users/${userId}/friends/${friendUserId}`);
        const targetFriendDocRef = doc(db, `Users/${friendUserId}/friends/${userId}`);

        await deleteDoc(userFriendDocRef);
        await deleteDoc(targetFriendDocRef);
    } catch (error) {
        alert('Failed to unfriend. Please try again.');
    }
};

export default unfriend;