import { db } from "../../../firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";

const postSurveyResults = async (userId, results) => {
    const resultsColRef = collection(db, `Results/${userId}/Survey`);

    try {
        //Get number of existing documents for doc name
        const snapshot = await getDocs(resultsColRef);
        const documentCount = snapshot.size;
        const newName = `Discover results ${documentCount + 1}`;

        const resultsDocRef = await addDoc(resultsColRef, {
            results: results,
            time: new Date(),
            name: newName
        });
    } catch (error) {
        console.error("Error posting survey results:", error.message);
    }
};

export default postSurveyResults;