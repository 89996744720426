import xIcon from "../../Assets/Images/xIcon.png";
import checkmarkIcon from "../../Assets/Images/checkmarkIcon.png";
import profileImage from "../../Assets/Images/defaultAccountImage.jpg";
import { FriendRequestModalStyles } from "./FriendRequestModalStyles";
import fetchUserProfile from "./fetchUserProfile";
import friendRequestResponse from "./friendRequestResponse";
import { useState } from "react";

const FriendRequestModal = ({ friendRequests, userId, firstName, lastName, setCurrentFriends, setFriendRequests, onClose }) => {
    const [filteredRequests, setFilteredRequests] = useState(friendRequests);

    const handleFriendRequestResponse = async (friendUserId, friendName, accept) => {
        try {
            setFilteredRequests(prevRequests =>
                prevRequests.filter(request => request.id !== friendUserId)
            );

            setFriendRequests(prevRequests =>
                prevRequests.filter(request => request.id !== friendUserId)
            );

            if (accept) {
                const newFriendInfo = await fetchUserProfile(friendUserId);
                const newFriend = {
                    name: friendName,
                    ...newFriendInfo
                };

                setCurrentFriends(prevFriends => [...prevFriends, newFriend])
            }

            await friendRequestResponse(userId, firstName, lastName, friendUserId, friendName, accept)
        } catch (error) {
            alert('Failed to handle friend request.');
        }
    };
    
    return (
        <FriendRequestModalStyles>
            <div className="modal-overlay">
                <div className="modal-content">
                    <h3>Friend Requests</h3>
                    <button className="close-button" onClick={onClose}>x</button>
                    <div className="request-section">
                        <ul className="request-list">
                            {filteredRequests.length > 0 ? (
                                filteredRequests.map((request) => (
                                    <li key={request.id} className="request-object">
                                        <div className="friend-info">
                                            <img src={profileImage} className="friend-profile-photo" alt="profile-picture" />
                                            <div className="friend-identifiers">
                                                <h4>{request.from}</h4>
                                                <p>@{request.fromUserName}</p>
                                            </div>
                                        </div>
                                        <div className="buttons-container">
                                            <button className="accept-button">
                                                <img src={checkmarkIcon} className="checkmark-icon" onClick={() => handleFriendRequestResponse(request.id, request.from, true)} alt="check"/>
                                            </button>
                                            <button className="decline-button">
                                                <img src={xIcon} className="x-icon" onClick={() => handleFriendRequestResponse(request.id, request.from, false)} alt="x"/>
                                            </button>
                                        </div> 
                                    </li>
                                ))
                            ) : (
                                <p>No more friend requests.</p>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </FriendRequestModalStyles>
    )
};

export default FriendRequestModal;