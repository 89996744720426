const performSurveyMerge = (userResults, friendResults) => {
    const mergedResults = {};
  
    //Add sum for each city to merge array
    const addResults = (result) => {
      result.results.forEach(result => {
        if (mergedResults[result.city]) {
          mergedResults[result.city] += result.sum;
        } else {
          mergedResults[result.city] = result.sum;
        }
      });
    };
  
    addResults(userResults);
    addResults(friendResults);
  
    return Object.keys(mergedResults).map(city => ({
      city: city,
      sum: mergedResults[city]
    }))
    .sort((a, b) => b.sum - a.sum);
  };
  

export default performSurveyMerge;