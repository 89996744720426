import { db } from "../../firebase";
import { getDocs, collection, doc, getDoc, query, where } from "firebase/firestore";

const fetchFriendGroups = async (userId) => {
   try {
        //Get all group doc ids
        const userGroupsRef = collection(db, "Users", userId, "groups");
        const userGroupsSnapshot = await getDocs(userGroupsRef);

        //Get group data
        const groups = [];

        for (const docSnapshot of userGroupsSnapshot.docs) {
            const groupId = docSnapshot.id;

            const groupDocRef = doc(db, "Groups", groupId);
            const groupDoc = await getDoc(groupDocRef);

            if (groupDoc.exists()) {
                //Fetch merge requests to attach to group object
                const mergeRequestsRef = collection(db, "Groups", groupId, "mergeRequests");
                const mergeRequestsQuery = query(mergeRequestsRef, where("sentBy", "!=", userId));
                const mergeRequestsSnapshot = await getDocs(mergeRequestsQuery);

                //Do not return request if user has already added their results
                const filteredRequests = mergeRequestsSnapshot.docs.filter(doc => {
                    const data = doc.data();
                    return !data.includedMembers.includes(userId);
                });

                const mergeRequests = filteredRequests.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));

                groups.push({
                    id: groupId,
                    ...groupDoc.data(),
                    requests: mergeRequests,
                });
            };
        };

        return groups;
   } catch (error) {
        return [];
   }
};

export default fetchFriendGroups;