import { useEffect, useRef } from "react";
import { NewHomeStyles } from "./NewHomeStyles";
import landingPageVideo from "../../Assets/Videos/landingPageVideo.mp4";
import profileImage from "../../Assets/Images/landingPageProfileImage.png";
import friendsImage from "../../Assets/Images/friendsImage.png";
import mergeIcon from "../../Assets/Images/merge.png";
import { useNavigate } from "react-router-dom";

const NewHome = () => {
    //Nav function
    const navigate = useNavigate();

    //Ref to store sections
    const sectionsRef = useRef([]);

    //Scroll slide effect
    useEffect(() => {
        const sections = document.querySelectorAll(".info-section");
        sectionsRef.current = sections;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("show");
                    }
                });
            },
            { 
                threshold: 0.15,
                rootMargin: "100px 0px 0px 0px"
            }
        );

        sections.forEach((section) => observer.observe(section));

        //Cleanup observer on unmount
        return () => {
            sectionsRef.current.forEach((section) => observer.unobserve(section));
        };
    }, []);

    //Ref to store the video element
    const videoRef = useRef(null); 

    //Video parallax effect on scroll
    useEffect(() => {
        const videoElement = videoRef.current;

        let animationFrameId;
        const handleScroll = () => {
            if (animationFrameId) cancelAnimationFrame(animationFrameId);
            animationFrameId = requestAnimationFrame(() => {
                const scrollPos = window.scrollY;
                //Adjust the top position of the video to create a parallax effect
                videoElement.style.top = `${-scrollPos * 0.2}px`;
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (animationFrameId) cancelAnimationFrame(animationFrameId);
        };
    }, []);

    /* IF THE USER IS ON SAFARI WITH LOW POWER MODE ENABLED, THE VIDEO WILL NOT AUTOPLAY */
    //Low power workaround
    useEffect(() => {
        const handleUserInteraction = () => {
            videoRef.current?.play().catch(err => console.log("Autoplay blocked:", err));
            window.removeEventListener("click", handleUserInteraction);
            window.removeEventListener("touchstart", handleUserInteraction);
        };
    
        window.addEventListener("click", handleUserInteraction);
        window.addEventListener("touchstart", handleUserInteraction);
    
        return () => {
            window.removeEventListener("click", handleUserInteraction);
            window.removeEventListener("touchstart", handleUserInteraction);
        };
    }, []);

    return (
        <NewHomeStyles>
            {/* Hero Section */}
            <div className="hero">
                <video
                    ref={videoRef}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="background-video"
                >
                    <source src={landingPageVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="hero-content">
                    <h1>Nina Trvl</h1>
                    <p>Your journey, your way.</p>
                </div>
            </div>
    
            {/* Info Container with Scroll Effect Sections */}
            <div className="info-container">
                {/* Section 1: Get Results */}
                <div className="info-section" id="white">
                    <div className="text-container">
                        <h2>Get Results</h2>
                        <p>Use our game or survey algorithm to get destination suggestions.</p>
                    </div>
                    <div className="buttons-container">
                        <button className="results-button" onClick={() => navigate("/game")}>Game</button>
                        <button className="results-button" onClick={() => navigate("/survey")}>Survey</button>
                    </div>
                </div>
    
                {/* Section 2: Save Your Results */}
                <div className="info-section" id="black">
                    <div className="graphic-container">
                        <img src={profileImage} id="account-image" alt="Save your results" />
                    </div>
                    <div className="text-container">
                        <h2>Save Your Results</h2>
                        <p>Create an account to save and access your results anytime.</p>
                        <button className="results-button" id="account-button" onClick={() => navigate("/profile-dashboard")}>Create account</button>
                    </div>
                </div>
    
                {/* Section 3: Connect with Friends */}
                <div className="info-section" id="white">
                    <div className="text-container">
                        <h2>Connect with Friends</h2>
                        <p>Add friends, share your results, and merge for combined recommendations.</p>
                        <button className="results-button" id="account-button" onClick={() => navigate("/learn-more")}>Learn more</button>
                    </div>
                    <div className="graphic-container">
                        <img src={friendsImage} alt="Connect with friends" />
                    </div>
                </div>
    
                {/* Section 4: Create Groups */}
                <div className="info-section" id="black">
                    <div className="graphic-container">
                        <img src={mergeIcon} id="account-image" alt="Create groups" />
                    </div>
                    <div className="text-container">
                        <h2>Create Groups</h2>
                        <p>Form friend groups and find the best destination for your group trip!</p>
                        <button className="results-button" id="account-button" onClick={() => navigate("/learn-more")}>Learn more</button>
                    </div>
                </div>
            </div>
        </NewHomeStyles>
    );
};

export default NewHome;