import styled from "styled-components";

export const DashboardStyle = styled.div`
.dashboard-container {
    width: 98%;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding-bottom: 10px;
}

.banner-image {
  width: 100%;
  height: 20vw;
  max-height: 250px;
  object-fit: cover;
}

.profile-content {
  display: flex; 
  flex-direction: column; 
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.profile-info {
  display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
}

.profile-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-image {
  width: clamp(60px, 15%, 80px);
  height: clamp(60px, 15%, 80px);
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin-bottom: 10px;
  margin-right: 5px;
}

.profile-text {
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
}

h2 {
  font-size: 150%;
  margin: 0;
  font-weight: 500;
}

.username {
  color: #666;
  font-size: 0.9em;
  margin-top: 5px;
}

.edit-profile-button {
  margin-left: auto;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  width: 100px;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.save-button {
  padding: 8px 16px;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.cancel-button {
  padding: 8px 16px;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.dashboard-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 98%;
}

.top-line, .bottom-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
}

.friends-container {
    width: 23%;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    height: 400px;
    overflow-y: scroll;
}



h3 {
    font-size: 1.2em;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 0px;
    padding-left: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f5f5f5;
}

ul {
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    list-style-type: none;
}

.dashboard-content .requests-bubble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  margin: 0 auto .5em auto;
  padding: 5px 15px;
  background: rgba(200, 200, 200, 0.7);
  border-radius: 15px / 50%;
  font-size: 14px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  position: relative;
  border: none;
  outline: none;
  transition: background 0.3s ease-in-out;
}

.requests-bubble:hover {
  background: rgba(180, 180, 180, 0.9);
}

/* Exclamation Icon */
.alert-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: orange;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  margin-left: 8px;
  position: relative;
}

.friend-object {
    width: 100%;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 50px;
    align-items: center;
    padding: 0 .5em;
}

.friend-info {
    display: flex;
    flex-direction: row;
    gap: .5em;
    align-items: center;
    padding: 5px 0;
}

.friend-pfp {
    width: 2.5em;
    height: 2.5em;
    border: 2px solid grey;
    border-radius: 25px;
}

.friend-identifiers {
    display: flex;
    flex-direction: column;
}

h4 {
    margin: 0;
    font-size: clamp(0.5em, 1.5vw, 1em);    
    padding-top: 5px;
    white-space: nowrap; /* Prevent text from wrapping onto a new line */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis;
}

p {
    margin: 0;
    font-size: clamp(0.5em, 1vw, .8em);
}

.friend-view-button {
    border: none;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    font-size: 10pt;
    font-family "Myriad Pro";
    background-color: #005fff;
    color: white;
    max-height: 35px;
    position: relative;
}

.friend-groups {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    min-height: 100%;
}

.friend-groups-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
}

.friend-groups-header h3 {
    border-bottom: none;
}

.create-group-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    font-weight: bold;
}

.group-object {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .5em .5em;
    align-items: center;
}

.view-group-button {
    border: none;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    font-size: 10pt;
    font-family "Myriad Pro";
    background-color: #005fff;
    color: white;
    max-height: 35px;
}

.results-container {
    width: 37.5%;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    height: 400px;
    overflow-y: scroll;
    margin-left: 20px; 
}

.friends-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.magnifying-glass-button {
  border: none;
  background: none;
}

.magnifying-glass {
    width: 15px;
    height: 15px;
}

.notification-bubble {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: orange;
    color: white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign-out-button {
  display: block;
  margin: 0 auto .5em auto;
  width: 80px;
  height: 40px;
  border: none;
  background: red;
  border-radius: 5px;
  color: white;
}
`;
