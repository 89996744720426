import styled from "styled-components";

export const MobileDashboardStyles = styled.div`
.dashboard-container {
    width: 98%;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding-bottom: 10px;
}

.banner-image {
  width: 100%;
  height: 20vw;
  max-height: 250px;
  object-fit: cover;
}

.profile-content {
  display: flex; 
  flex-direction: column; 
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.profile-info {
  display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
}

.profile-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-image {
  width: clamp(60px, 15%, 80px);
  height: clamp(60px, 15%, 80px);
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin-bottom: 10px;
  margin-right: 5px;
}

.profile-text {
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
}

h2 {
  font-size: 150%;
  margin: 0;
  font-weight: 500;
}

.username {
  color: #666;
  font-size: 0.9em;
  margin-top: 5px;
}

.username-input {
    width: 135px;
}

.edit-profile-button {
  margin-left: auto;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  width: 100px;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.save-button {
  padding: 8px 12px;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.cancel-button {
  padding: 8px 12px;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.dashboard-content {
    padding: 16px;
}

.top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stat-buttons {
    display: flex;
    gap: 16px;
}

.stat-button-container {
    position: relative;
    display: inline-block;
}

.notification-bubble {
    position: absolute;
    top: -3px !important;
    right: -3px !important;
    background-color: orange;
    color: white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stat-button {
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 16px;
    text-align: center;
    width: 80px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.stat-button h3 {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
    color: #333;
}

.stat-value {
    font-size: 18px;
    font-weight: bold;
    color: #007bff;
}

.stat-button.active {
    background-image: linear-gradient(to right, #005e84, #009999);
    transform: translateY(-4px);
    outline: none;
}

.stat-button.active h3, .stat-button.active .stat-value {
    color: white;
}


.dashboard-content {
    width: 100%;
}

.top-line {
    padding-bottom: 1em;
    justify-content: center;
}

.friends-content {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

.friends-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.friends-header h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
}

.requests-container {
  display: flex;
  justify-content: center;
  margin: 15px auto 10px auto;
  width: 200px;
}

.requests-container .requests-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  background: rgba(200, 200, 200, 0.7);
  border-radius: 15px / 50%;
  font-size: 14px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background 0.3s ease-in-out;
  white-space: nowrap;
}

.requests-bubble:hover {
  background: rgba(180, 180, 180, 0.9);
}

.alert-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: orange;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  margin-left: 6px;
  position: relative;
}

.magnifying-glass-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.magnifying-glass {
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease;
}

.magnifying-glass-button:hover .magnifying-glass {
    transform: scale(1.2);
}

.list-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.list-container li {
    margin-bottom: 15px;
}

.list-container p {
    font-size: .75rem;
}

.friend-object {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.friend-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.friend-pfp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #f0f0f0;
}

.friend-identifiers h4 {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0;
    padding-top: 5px;
}

.friend-identifiers p {
    font-size: 0.875rem;
    color: #777;
    margin-bottom: 0;
}

.friend-view-button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    font-size: 0.875rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.friends-content ul li,
.groups-content ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

ul li:last-child {
    margin-bottom: 0;
}

p {
    font-size: 1rem;
    color: #888;
}

.groups-content {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

.friend-groups-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.friend-groups-header h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
}

.create-group-button {
    background-color: #007bff;
    color: white;
    border: none;
    font-size: 1.5rem;
    aspect-ratio: 1/1;
    width: 36px;
    height: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.create-group-button:hover {
    background-color: #0056b3;
    transform: scale(1.1);
}

.list-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.friend-object {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.friend-object:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.group-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.group-info h4 {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
}

.group-info p {
    font-size: 0.875rem;
    color: #777;
    margin-bottom: 0;
}

.friend-view-button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    font-size: 0.875rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    position: relative;
}

.friend-view-button:hover {
    background-color: #0056b3;
}

.friend-view-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #0056b3;
}

.empty-text {
    font-size: 1rem;
    color: #888;
    text-align: center;
}

.result-tabs, .merge-tabs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 15px;
}

/* Tab Buttons */
.result-tab, .merge-tab {
  background: rgba(220, 220, 220, 0.7);
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.result-tab:hover, .merge-tab:hover {
  background: rgba(200, 200, 200, 0.9);
}

/* Active Tab */
.result-tab.active, .merge-tab.active {
  background: #444;
  color: white;
}

/* Result Content */
.result-content {
  background: rgba(255, 255, 255, 0.8); /* Light, soft background */
  border-radius: 12px;
  padding: 20px;
  margin-top: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Titles inside result-content */
.result-content h3 {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 0;
}

/* List container */
.list-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 550px;
  overflow-y: auto;
}

/* Styling for the UL (List of results) */
.list-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.friend-group-heading:hover {
  background-color: #e0e0e0;
}

.friend-group-heading span {
  display: inline-block;
  font-size: 1em;
}

.friend-results {
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  padding: 10px;
}

.friend-results .heading {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

.friend-results p {
    font-size: .75rem;
}

.sign-out-button {
  display: block;
  margin: 0 auto .5em auto;
  width: 100px;
  height: 40px;
  border: none;
  background: red;
  border-radius: 5px;
  color: white;
  white-space: nowrap;
}
`;