import styled from "styled-components";

export const SearchUsersStyles = styled.div`
    h3 {
        margin-bottom: 20px;
        margin-right: 10px;
    }

    .search-bar-container {
        margin-bottom: 10px;
    }

    .search-item {
        align-items: center;
        border-bottom: 1px solid gray;
    }

    .search-item:last-child {
        border-bottom: none;
    }

    .search-item span {
        max-width: 65%;
    }

    .add-friend-button {
        font-size: .75em;
        background-color: blue;
        border: none;
        border-radius: 10px;
        color: white;
        height: 40px;
    }

    .buttons-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .decline-button, .accept-button {
        border: none;
        background: none;
        padding: 0;
    }

    .checkmark-icon, .x-icon {
        width: 25px;
        height: 25px;
    }
`;