import { db } from "../../firebase";
import { doc, collection, getDoc, setDoc, deleteDoc } from "firebase/firestore";

const pinResult = async (userId, result) => {
    try {
        const subcollection = result.attributeTotals ? "Game" : "Survey"
        const userResultsRef = doc(db, 'Results', userId);
        let resultDocRef;
        if (subcollection == "Game") {
            const gameResultsRef = collection(userResultsRef, 'Game');
            resultDocRef = doc(gameResultsRef, result.id);
        } else {
            const surveyResultsRef = collection(userResultsRef, "Survey");
            resultDocRef = doc(surveyResultsRef, result.id);
        }
  
        const resultDoc = await getDoc(resultDocRef);
  
        if (resultDoc.exists()) {
          const resultData = resultDoc.data();

          let pinnedColRef;
          if (subcollection == "Game") {
            pinnedColRef = collection(userResultsRef, 'Pinned');
          } else {
            pinnedColRef = collection(userResultsRef, 'SurveyPinned');
          }
          
          const newDocRef = doc(pinnedColRef, result.id);
  
          await setDoc(newDocRef, resultData);
          await deleteDoc(resultDocRef);
        } else {
          return;
        }
    } catch (error) {
      console.error("Error moving document: ", error.message);
    }
};

export default pinResult;