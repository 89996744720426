import { db } from "../../firebase";
import { doc, collection, getDoc, setDoc, deleteDoc } from "firebase/firestore";

const unpinResult = async (userId, result) => {
    try {
        const userResultsRef = doc(db, `Results`, userId);
        const subcollection = result.attributeTotals ? "Game" : "Survey";

        let pinnedCollectionRef;
        if (subcollection == "Game") {
          pinnedCollectionRef = collection(userResultsRef, 'Pinned');
        } else {
          pinnedCollectionRef = collection(userResultsRef, "SurveyPinned");
        }
        
        const resultDocRef = doc(pinnedCollectionRef, result.id);
  
        const resultDoc = await getDoc(resultDocRef);
  
        if (resultDoc.exists()) {
          const resultData = resultDoc.data();
  
          let resultsRef;
          if (subcollection == "Game") {
            resultsRef = collection(userResultsRef, 'Game');
          } else {
            resultsRef = collection(userResultsRef, 'Survey');
          }
          const newDocRef = doc(resultsRef, result.id);
  
          await setDoc(newDocRef, resultData);
          await deleteDoc(resultDocRef);
        } else {
          return;
        }
      } catch (error) {
        console.error("Error moving document: ", error.message);
      }
};

export default unpinResult;