import { db } from "../../firebase";
import { doc, setDoc, collection } from "firebase/firestore";

const postSurveyMergeResult = async (userId, firstName, lastName, friend, result) => {
    const mergeDocRef = doc(collection(db, 'Merges')); // Auto-generated ID
    const mergeName = `${firstName} and ${friend.firstName}'s merge`;

    const mergeDocData = {
        name: mergeName,
        top8Destinations: result,
        userIds: [userId, friend.userId],
        userNames: {
            [userId]: `${firstName} ${lastName}`,
            [friend.userId]: `${friend.firstName} ${friend.lastName}`
        },
        createdAt: new Date()
    };

    await setDoc(mergeDocRef, mergeDocData);
};

export default postSurveyMergeResult;