import { db } from "../../firebase";
import { doc, setDoc, collection, query, where, getDocs } from "firebase/firestore";

const changeUserName = async (userId, newUsername) => {
    if (!newUsername || newUsername === "") {
        throw new Error('Username cannot be blank');
    }

    newUsername = newUsername.trim();
    
    //Check username uniqueness
    const usersColRef = collection(db, 'Users');
    const displayNameQuery = query(usersColRef, where('userName', '==', newUsername));
    const querySnapshot = await getDocs(displayNameQuery);
    if (querySnapshot.empty) {
        const userDocRef = doc(db, 'Users', userId);
        await setDoc(userDocRef, { userName: newUsername }, { merge: true });
    } else {
        throw new Error('Username not available');
    }
};

export default changeUserName;