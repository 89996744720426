import React, { useEffect, useState } from 'react';
import { DashboardProvider } from './DashboardProvider';
import DesktopDashboard from './DesktopDashboard';
import MobileDashboard from './MobileDashboard';
import { useUserAuth } from '../../UserAuthContext';
import SignInSignUpModal from '../../Modals/survey/signInSignUpModal';

const Dashboard = () => {
    const [isMobile, setIsMobile] = useState(false);
    const {user} = useUserAuth();
    const [startCollapsed, setStartCollapsed] = useState(false);

    useEffect(() => {
        const checkScreenWidth = () => {
            const isMobile = window.innerWidth <= 768;
            setIsMobile(isMobile);
            
            // Set the default collapsed state based on the screen width (collapsed for mobile)
            if (isMobile) {
                setStartCollapsed(true);
            } else {
                setStartCollapsed(false);
            }
        };

        checkScreenWidth();
        window.addEventListener('resize', checkScreenWidth); //Update on resize

        return () => window.removeEventListener('resize', checkScreenWidth);
    }, []);

    if (!user) {
        return <SignInSignUpModal heading="Sign in to view your profile" />;
    };

    return (
        <DashboardProvider startCollapsed={startCollapsed}>
            {isMobile ? <MobileDashboard /> : <DesktopDashboard />}
        </DashboardProvider>
    );
};

export default Dashboard;