import styled from "styled-components"

export const FriendRequestModalStyles = styled.div`

    h3 {
        margin: .0 1em .5em 0;
        background: none;
        border-bottom: 1px solid gray;
        padding-
    }

    h4 {
        font-size: 1.25em;
        margin: 0;
    }

    p {
        margin: 0;
        font-size: .75em;
    }

    .request-list {
        padding: 0;
    }

    .request-section {
        margin-top: .5em;
    }

    .request-object {
        width: 95%;
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-height: 50px;
        align-items: center;
    }

    li:last-child {
        border-bottom: none;
    }

    .friend-info {
        display: flex;
        flex-direction: row;
        gap: .5em;
        align-items: center;
    }

    .friend-profile-photo {
        width: 2.5em;
        height: 2.5em;
        border: 2px solid grey;
        border-radius: 25px;
    }

    .friend-identifiers {
        display: flex;
        flex-direction: column;
    }

    .buttons-container {
        display: flex;
        gap: 10px;
    }

    .accept-button, .decline-button {
        border: none;
        background: none;
        text-align: center;
        justify-content: center;
        padding: 0;
    }

    .checkmark-icon, .x-icon {
        width: 25px;
        height: 25px;
    }

`;