import { db } from "../../firebase";
import { getDocs, collection, query, where } from "firebase/firestore";

//Helper function to split array into chunks
const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};

const fetchGroupResults = async (userId) => {
    try {
        //Get user group ids
        const groupIdsRef = collection(db, `Users/${userId}/groups`);
        const groupIdsSnapshot = await getDocs(groupIdsRef);

        const groupIds = [];
        groupIdsSnapshot.forEach((doc) => {
            groupIds.push(doc.id);
        });

        if (groupIds.length === 0) {
            return [];
        }

        //Break groupIds into chunks of 10 (Firestore "in" query value limit)
        const groupIdChunks = chunkArray(groupIds, 10);

        const groupResults = [];
        for (const groupIdChunk of groupIdChunks) {
            const mergesRef = collection(db, "Merges");
            const mergesQuery = query(mergesRef, where("groupId", "in", groupIdChunk));
            const mergesSnapshot = await getDocs(mergesQuery);

            mergesSnapshot.forEach((doc) => {
                groupResults.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
        }

        return groupResults;
    } catch (error) {
        console.error("Error fetching group results", error.message);
    }
};

export default fetchGroupResults;